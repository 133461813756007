import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './styles/main.scss';

class Lottery extends Component {
  render() {
    return (
      <div className="policy">
        <div className="container">
          <div className="policy-wrapper">
            <Link to="/">
              <img
                src={require('./assets/icons/i-logo.svg')}
                className="policy-logo"
                alt="Mr.Tomato"
              />
            </Link>
          </div>
          <div className="policy-container">
            <h1 className="policy-title">
              ПРАВИЛА ПРОВЕДЕННЯ <br /> РОЗІГРАШУ ПРИЗУ РЕКЛАМНОЇ АКЦІЇ <br />{' '}
              «5 000 ГРИВЕНЬ ЗА ВІДГУК»
            </h1>
            <p className="policy-text">
              <b>
                1. Інформація про організатора та виконавця розіграшу призу
                рекламної акції «5 000 гривень за відгук» (надалі по тексту –
                Розіграш)
              </b>{' '}
              <br /> <br />
              1.1. Організатором і виконавцем Розіграшу є Товариство з обмеженою
              відповідальністю «Траст Соурсінг», яке зареєстровано відповідно до
              законодавства України за адресою: Україна, 03062, м. Київ,
              Святошинський район, проспект Перемоги, будинок 65, літера «А»,
              ідентифікаційний код 39712676 (надалі по тексту – Організатор).
              <br /> <br />
              1.2. Для проведення Розіграшу та підведення його підсумків
              Організатор створює Організаційний комітет (надалі по тексту –
              Оргкомітет). Оргкомітет у своїх діях керується чинним
              законодавством України та цими Правилами і несе відповідальність
              за їх дотримання.
              <br /> <br />
              1.3. Розіграш проводиться з метою збору статистичних даних для
              виявлення рівня задоволення/незадоволення якістю обслуговування,
              харчування та атмосфери в закладах громадського харчування, що
              дасть змогу закладам харчування покращити якість свого сервісу.
              <br /> <br />
              <b>2. Територія, строки та процедура проведення Розіграшу</b>
              <br /> <br />
              2.1. Розіграш, включаючи всі його етапи, проводиться на всій
              території України за виключенням тимчасово окупованих територій
              Донецької та Луганської областей і Автономної Республіки Крим
              (надалі по тексту – Територія) в період з «26» квітня 2019 по «31»
              травня 2019 року включно (надалі по тексту – Період проведення
              Розіграшу) у декілька етапів у відповідності з Правилами
              Розіграшу.
              <br /> <br />
              2.2. Етапи проведення Розіграшу:
              <br /> <br />
              2.2.1. Перший етап Розіграшу проводиться у період з 00 годин 00
              хвилин «26» квітня 2019 по 23 годину 59 хвилин 59 секунд «31»
              травня 2019 року включно за місцевим Київським часом.
              <br /> <br />
              2.3.Для участі у Розіграші Учаснику необхідно:
              <br />
              2.3.1. натиснути на посиланні для переходу до анкети Учасника
              Розіграшу або до форми відгуку про відповідний заклад громадського
              харчування. Відповідне посилання Організатор поширює за допомогою
              QR кодів, зовнішньої реклами, реклами в Інтернеті і соціальних
              мережах, публікації його на своєму власному сайті та своїх
              профайлах в соціальних мережах, а також на сайтах та профайлах в
              соціальних мережах третіх осіб.
              <br />
              2.3.2. після переходу до анкети Учасник повинен відповісти на всі
              питання, що містяться в анкеті, і які пов’язані з відповідним
              закладом громадського харчування, стосуються його меню, якості
              обслуговування, тощо.
              <br />
              2.3.3. після надання відповідей в анкеті або залишення відгуку про
              відповідний заклад громадського харчування Учасник надає свої
              контактні дані: П.І.Б., номер телефону, адресу електронної пошти.
              <br />
              2.3.4. у разі потреби Учасник також надає через спеціальну форму
              фото розрахункового документу, який підтверджує споживання послуг
              відповідного закладу громадського харчування.
              <br />
              2.3.5 кількість таких розрахункових документів та відгуків не
              обмежується. Більша кількість розрахункових документів та відгуків
              підвищує шанси Учасника на виграш призу.
              <br />
              2.3.6. після оприлюднення результатів розіграшу і обрання
              Переможця(ів), Переможець завантажує через спеціальну форму
              фотографію із достатньо чітким зображенням власного обличчя
              (наприклад, фото ззаду де не видно обличчя Переможця або фото де
              Переможця сфотографовано дуже далеко, що не дає можливості його
              ідентифікувати, не приймаються).
              <br /> <br />
              2.4. Розіграш в період відповідного етапу проводиться лише серед
              тих Учасників, які виконали всі умови даних Правил протягом
              періоду дії відповідного етапу Розіграшу.
              <br /> <br />
              2.5. Переможці кожного з етапів визначаються Організатором окремо.
              Кожен з переможців має право на приз відповідно до цих Правил.
              <br /> <br />
              2.6. Термін проведення Розіграшу може бути змінений Організатором
              за його власним рішенням самостійно або у зв'язку з істотною
              зміною обставин, які не могли бути передбачені Організатором.
              <br /> <br />
              <b>3. Учасники Розіграшу</b>
              <br /> <br />
              3.1. Учасниками Розіграшу (надалі по тексту – Учасники Розіграшу
              або Учасники) є фізичні особи, які: є громадянами України; досягли
              18 років; погодилися з усіма умовами Розіграшу, визначеними цими
              Правилами.
              <br /> <br />
              3.2. Не визнаються Учасниками Розіграшу і не мають права брати в
              ньому участь члени Оргкомітету, а також особи, які не відповідають
              умовам або не виконують умови даних Правил.
              <br /> <br />
              <b>4.Призи Розіграшу</b>
              <br /> <br />
              4.1. Фонд призів формується на розсуд Організатора.
              <br /> <br />
              4.2. Під призом розуміється грошова сума у гривні, розмір якої
              залежить від призового місця, згідно з цими Правилами.
              <br /> <br />
              4.3. Приз виплачується Організатором у безготівковому вигляді,
              шляхом перерахунку відповідної суми грошових коштів у гривні з
              поточного рахунку Організатора на особистий банківський рахунок
              Переможця Розіграшу.
              <br /> <br />
              4.4. У випадку, якщо Переможець Розіграшу бажає, щоб Організатор
              виплатив належний цьому Переможцеві приз третій особі, Переможець
              має надати Організаторові оригінал відповідної нотаріальної
              довіреності, складеної Переможцем, яка уповноважує таку третю
              особу отримати приз Переможця від Організатора на банківський
              рахунок цієї третьої особи з чіткою вказівкою всіх необхідних
              банківських реквізитів, які необхідні для перерахунку грошових
              коштів. <br /> <br />
              4.5. Відповідальність та всі банківські витрати, пов’язані з
              невірним зазначенням Переможцем, або його уповноваженою особою,
              банківських реквізитів для перерахунку призу, несе Переможець. В
              тому числі але не виключно, Переможець несе витрати у випадку
              повернення грошової суми призу на банківський рахунок
              Організатора, у зв’язку з тим, що банківські реквізити Переможця
              не відповідають дійсності, не актуальні, належать третій особі, не
              уповноваженій належним чином, тощо. <br /> <br />
              4.6. Кількість призів кожного з етапів: 5 (п’ять) штук:
              <br />
              4.6.1. Розмір призу за перше місце становить 5 000,00 гривень
              (П’ять тисяч гривень нуль копійок).
              <br />
              4.6.2. Розмір призу за друге місце становить 500,00 гривень
              (П’ятсот гривень нуль копійок).
              <br />
              4.6.3. Розмір призу за третє місце становить 500,00 гривень
              (П’ятсот гривень нуль копійок). <br />
              4.6.4. Розмір призу за четверте місце становить 500,00 гривень
              (П’ятсот гривень нуль копійок).
              <br />
              4.6.5. Розмір призу за п’яте місце становить 500,00 гривень
              (П’ятсот гривень нуль копійок).
              <br />
              <br />
              4.7. Розмір призу, зазначений в цих Правилах, вказаний з
              урахуванням 19.5% податків, зборів та інших обов’язкових платежів,
              передбачених чинним законодавством України, які Переможець
              зобов’язаний буде сплатити самостійно з суми призу або які будуть
              сплачені Організатором, як податковим агентом Переможця, у
              відповідності до вимог чинного законодавства України.
              Оподаткування осіб, які отримали приз, проводиться відповідно до
              чинного законодавства України. <br />
              <br />
              4.8. Призовий фонд кожного етапу і призового місця Розіграшу
              обмежений і складає зазначену в п. 4.6 цих Правил кількість та
              суму. <br />
              <br />
              4.9. Організатор залишає за собою право збільшити кількість призів
              Розіграшу або додатково включити в Розіграш інші призи, не
              передбачені цими Правилами. <br />
              <br />
              4.10. Відповідальність Організатора щодо видачі призів обмежена
              виключно вищезгаданою кількістю призів.
              <br />
              <br /> 4.11. Заміна призів Розіграшу будь-яким іншим благом не
              допускається. <br />
              <br />
              4.12. Організатор здійснює виплату призу відповідному Переможцеві
              протягом 30 (Тридцяти) календарних днів з дати наступної за дату
              визначення відповідного Переможця. <br />
              <br />
              4.13. При виплаті призу Організатором і отримання призу
              Переможцем, сторони самостійно сплачують всі банківські комісії,
              що стягуються їхніми обслуговуючими банками при такого роду
              грошових банківських операціях. <br />
              <br />
              4.14. Для того аби Організатор мав змогу ініціювати виплату призу
              Переможцеві, останній зобов’язаний надати Організаторові повні
              необхідні банківські реквізити (свої або уповноваженої особи),
              паспортні дані (повне прізвище, ім’я, по-батькові, адреса
              реєстрації, серія та номер паспорту, дату видачі паспорту та ким
              він виданий) українською мовою, а також, за наявності,
              індивідуальний податковий номер.
              <br />
              <br /> 4.15. Організатор має право відмовити у виплаті призу на
              банківські рахунки, які відкриті у банківських установах тимчасово
              окупованих територій Донецької та Луганської областей і Автономної
              Республіки Крим.
              <br />
              <br />
              <b>5. Умови визначення Переможців Розіграшу </b>
              <br />
              <br />
              5.1. Визначення Учасників, які отримають право на отримання призу
              (по всьому тексту цих правил вказані як «Переможці»), здійснюється
              відповідно до цих Правил, шляхом визначення Переможця за допомогою
              програмного коду{' '}
              <a href="https://www.random.org">https://www.random.org</a> за
              принципом випадкового відбору серед групи Учасників відповідного
              етапу Розіграшу, з врахуванням умов Розіграшу, описаних в цих
              Правилах,, а також враховуючи кількість відгуків про заклади
              харчування. Кількість унікальних відгуків збільшує шанс на виграш
              призу.
              <br />
              <br />
              5.2. Визначення Переможців, які отримали право на отримання призу
              кожного етапу Розіграшу, відбувається в офісі Організатора, за
              участю представників Оргкомітету. <br />
              <br />
              5.3. Результати визначення Переможців кожного етапу Розіграшу, які
              отримали право на отримання призу, оформляються протоколом, який
              підписується представниками Оргкомітету. <br />
              <br />
              5.4. Результати кожного етапу Розіграшу, тобто інформація про
              Переможців відповідного етапу, буде розміщена на сайті{' '}
              <a href="https://www.facebook.com/mistertomato/,">
                https://www.facebook.com/mistertomato/,
              </a>{' '}
              а також Переможцям буде здійснено дзвінок та/або надіслано
              смс-повідомлення на номер мобільного телефону та/або надіслано
              лист на електронну адресу, зазначену ними під час реєстрації на
              участь в Розіграші. <br />
              <br />
              5.5. Один Учасник може стати лише один раз Переможцем в рамках
              даного Розіграшу незалежно від кількості та дат(и) його реєстрацій
              на участь в Розіграші або реєстрацій на ім’я цього Учасника.{' '}
              <br />
              <br />
              5.6. Детальні умови Розіграшу, а саме ці Правила, так само як і
              інша інформація, що стосується Розіграшу, розміщуються на сайті{' '}
              <a href="https://mrtomato.com/lotteryrules">
                https://mrtomato.com/lotteryrules
              </a>
              .<br />
              <br />
              5.7. Протягом 30 (Тридцяти) календарних днів з дня сповіщення
              Організатором про результати відповідного етапу Розіграшу,
              Переможець повинен надати Організатору всю інформацію, яка
              необхідна Організатору для перерахунку призу Переможцеві
              (щонайменше: номер банківської картки, номер банківського рахунку,
              назву та МФО банку). Невиконання цього пункту Переможцем означає
              його безумовну відмову від призу, у зв’язку з чим приз залишається
              у власності Організатора. <br />
              <br />
              5.8. Якщо Переможець Розіграшу з будь-яких причин, незалежних від
              Організатора, не має можливості отримати приз протягом 30
              (Тридцяти) календарних днів з дня сповіщення Організатором про
              результати відповідного етапу Розіграшу (в т.ч. якщо номер
              мобільного телефона та адреса електронної пошти Учасника були не
              вказані, якщо поточний рахунок з використанням електронного
              платіжного засобу, відкритого на ім’я Учасника для отримання призу
              відсутній, тощо), такий Учасник не має права на отримання призу,
              жодних додаткових заохочень, компенсацій та інших виплат від
              Організатора. За даних обставин приз залишається у власності
              Організатора. <br />
              <br />
              <b> 6. Переможці Розіграшу</b>
              <br />
              <br />
              6.1. Взявши участь у Розіграші, Учасники надають Організатору
              (який є володільцем бази персональних даних ТОВ «Траст Соурсінг»)
              свою згоду на:
              <br />
              6.1.1. добровільну, повну і безумовну (беззастережну) згоду на
              обробку його (її) персональних даних, як суб’єкта персональних
              даних у значенні Закону України «Про захист персональних даних» у
              базі персональних даних ТОВ «Траст Соурсінг» (надалі по тексту –
              БПД). Метою, ціллю та призначенням обробки персональних даних
              суб’єкта персональних даних в БПД є реалізація підприємницької
              діяльності ТОВ «Траст Соурсінг», а також захист від ризиків, які
              пов’язані з підприємницькою діяльністю, відповідно до вимог
              законодавства України, в тому числі нормативно-правових актів
              Національного банку України та/або статуту ТОВ «Траст Соурсінг»
              та/або внутрішніх нормативних документів ТОВ «Траст Соурсінг», а
              також з метою проведення Розіграшу. Суб’єкт персональних даних БПД
              надає право ТОВ «Траст Соурсінг» на обробку отриманих від нього
              персональних даних в обсязі, який визначається останнім на свій
              розсуд відповідно до сформульованої мети. Суб’єкт персональних
              даних БПД надає право ТОВ «Траст Соурсінг» на свій розсуд
              визначати порядок обробки, використання, доступу та поширення (в
              повному обсязі або частково) персональних даних суб’єкта
              персональних даних БПД іншим суб’єктам відносин, пов’язаних із
              персональними даними. Цим суб’єкт персональних даних БПД
              засвідчує, що він ознайомлений про: включення його (її)
              персональних даних до БПД; про свої права, які визначені Законом
              України «Про захист персональних даних»; мету збору даних та осіб,
              яким передаються та/або можуть бути передані його (її) персональні
              дані. <br />
              6.1.2. використання такої інформації Організатором з метою
              проведення Розіграшу відповідно до чинного законодавства України;
              <br />
              6.1.3. отримання листів на пошту та/або смс-повідомлень на номер
              мобільного телефону Учасника від Організатора з інформацією про
              послуги, акції, які пропонує Організатор.
              <br />
              <br /> 6.2. Власником БПД є Товариство з обмеженою
              відповідальністю «Траст Соурсінг», яке зареєстровано відповідно до
              законодавства України за адресою: Україна, 03062, м. Київ,
              Святошинський район, проспект Перемоги, будинок 65, літера «А»,
              ідентифікаційний код 39712676.
              <br />
              <br /> 6.3. Учасник має право: 1) знати про джерела збирання,
              місцезнаходження своїх персональних даних, мету їх обробки,
              місцезнаходження або місце проживання (перебування) володільця чи
              розпорядника персональних даних або дати відповідне доручення щодо
              отримання цієї інформації уповноваженим ним особам, крім випадків,
              встановлених законом; 2) отримувати інформацію про умови надання
              доступу до персональних даних, зокрема інформацію про третіх осіб,
              яким передаються його персональні дані; 3) на доступ до своїх
              персональних даних; 4) отримувати не пізніш як за тридцять
              календарних днів з дня надходження запиту, крім випадків,
              передбачених законом, відповідь про те, чи обробляються його
              персональні дані, а також отримувати зміст таких персональних
              даних; 5) пред’являти вмотивовану вимогу володільцю персональних
              даних із запереченням проти обробки своїх персональних даних; 6)
              пред'являти вмотивовану вимогу щодо зміни або знищення своїх
              персональних даних будь-яким володільцем та розпорядником
              персональних даних, якщо ці дані обробляються незаконно чи є
              недостовірними; 7) на захист своїх персональних даних від
              незаконної обробки та випадкової втрати, знищення, пошкодження у
              зв'язку з умисним приховуванням, ненаданням чи несвоєчасним їх
              наданням, а також на захист від надання відомостей, що є
              недостовірними чи ганьблять честь, гідність та ділову репутацію
              фізичної особи; 8) звертатися із скаргами на обробку своїх
              персональних даних до Уповноваженого або до суду; 9) застосовувати
              засоби правового захисту в разі порушення законодавства про захист
              персональних даних; 10) вносити застереження стосовно обмеження
              права на обробку своїх персональних даних під час надання згоди;
              11) відкликати згоду на обробку персональних даних; 12) знати
              механізм автоматичної обробки персональних даних; 13) на захист
              від автоматизованого рішення, яке має для нього правові наслідки.
              <br />
              <br />
              <b>7. Порядок і спосіб інформування про умови Розіграшу:</b>{' '}
              <br />
              <br />
              7.1. Інформування щодо Правил та умов Розіграшу здійснюється за
              допомогою анонсування Розіграшу та розміщення офіційних Правил та
              умов Розіграшу на web-сайті, який знаходиться в мережі Інтернет за
              наступною адресою:{' '}
              <a href="https://mrtomato.com/lotteryrules">
                https://mrtomato.com/lotteryrules
              </a>
              , а також може здійснюватися на розсуд Організатора за допомогою
              розсилки листів електронною поштою, смс-повідомлень на наявні в
              Організатора номери мобільних телефонів, шляхом розміщення
              зовнішньої реклами, розміщення QR кодів в закладах харчування,
              розміщення реклами в соціальних мережах, веб-сайтах третіх осіб,
              тощо. <br />
              <br />
              7.2. Ці Правила можуть бути змінені та/або доповнені Організатором
              протягом всього строку проведення Розіграшу. Зміни та/або
              доповнення цих Правил та умов Розіграшу можливі у випадку їх
              затвердження Організатором та їх оприлюднення у тому ж порядку, що
              визначений для інформування про умови Розіграшу. Такі
              зміни/доповнення набирають чинності з моменту оприлюднення, якщо
              інше не буде спеціально визначене безпосередньо
              змінами/доповненнями до цих Правил та умов.
              <br />
              <br /> 7.3. Якщо будь-яка умова цих Правил не відповідає вимогам
              чинного законодавства України, або в процесі розіграшу стає такою,
              що не відповідає вимогам чинного законодавства України, в такому
              випадку має застосовуватися відповідне положення законодавства,
              при цьому всі інші положення та умови цих Правил залишаються
              чинними, продовжують діяти і бути обов’язковими для Учасників.{' '}
              <br />
              <br />
              <b>8. Надання інформації</b> <br />
              <br />
              8.1. Для отримання призу в рамках цього Розіграшу Учасник повинен
              надати Організатору інформацію (в т.ч. особисті дані), визначену
              даними Правилами. <br />
              <br />
              8.2. Ця інформація може бути змінена Учасником протягом всього
              строку проведення Розіграшу шляхом додаткового інформування
              Організатора. <br />
              <br />
              8.3. Беручи участь в Розіграші, кожен Учасник тим самим
              підтверджує свою згоду на використання Організатором наданої
              інформації з маркетинговою та/або будь-якою іншою метою, методами,
              що не суперечать чинному законодавству України (в т.ч. шляхом
              передачі третім особам), зокрема, на безоплатне використання його
              імені, прізвища, фотографії, інтерв'ю або інших матеріалів про
              нього з рекламною/маркетинговою метою, в т.ч. право публікації в
              ЗМІ, будь-яких друкованих, аудіо- та відео- матеріалах, інтерв'ю у
              ЗМІ, у разі отримання призу, а також для надсилання інформації,
              повідомлень (в т. ч. рекламного характеру) тощо, без будь-яких
              обмежень за територією, часом та способом використання, і таке
              використання жодним чином не відшкодовуватиметься Організатором
              та/яабо будь-якою третьою особою. Надання такої згоди також
              розглядається у розумінні статей 296, 307, 308 Цивільного Кодексу
              України. <br />
              <br />
              8.4. Організатор Розіграшу гарантує, що під час збору і подальшого
              використання отриманої інформації він буде дотримуватися всіх
              положень чинного законодавства України щодо недоторканності
              особистої інформації, захисту персональних даних. Надання
              інформації іншим особам можливе лише на підставах і в порядку,
              визначеному чинним законодавством України. <br />
              <br />
              <b>9. Обмеження Розіграшу</b> <br />
              <br />
              9.1. Організатор не несе відповідальності за роботу інтернет
              провайдерів, операторів зв'язку, будь-які помилки поштових,
              кур'єрських або інших служб, в результаті яких поштові та
              електронні відправлення не надійшли або надійшли із запізненням,
              були загублені або пошкоджені. <br />
              <br />
              9.2. Організатор не несе відповідальності стосовно подальшого
              використання наданих призів Переможцями Розіграшу після їх
              одержання, та за неможливість Переможців Розіграшу скористатися
              наданим призом з будь-яких причин. <br />
              <br />
              9.3. Організатор не вступає в будь-які спори щодо визнання
              будь-яких осіб Учасниками та прав на отримання призів. Організатор
              не бере на себе відповідальності за визначення прав сторін у
              будь-яких суперечках.
              <br />
              <br /> 9.4. Учасники самостійно несуть відповідальність за
              достовірність наданої ними інформації (у тому числі своїх
              банківських реквізитів, інформації щодо контактів з ними та
              адреси).
              <br />
              <br /> 9.5. У разі виникнення ситуації, яка допускає неоднозначне
              тлумачення даних Правил, будь-яких спірних питань та/або питань,
              не врегульованих цими Правилами, остаточне рішення приймається
              Організатором відповідно до вимог чинного законодавства України.
              Рішення Організатора є остаточним і оскарженню не підлягає.
              Результати Розіграшу є остаточними і не підлягають перегляду.{' '}
              <br />
              <br />
              9.6. Організатор не несе відповідальності у разі настання
              форс-мажорних обставин, таких як стихійні лиха, пожежа, повінь,
              військові дії будь-якого характеру, блокади, хакерські атаки,
              суттєві зміни у законодавстві, що діють на території проведення
              Розіграшу, інші непідвладні контролю з боку Організатора
              обставини. <br />
              <br />
              <b>10. Інші умови Розіграшу</b> <br />
              <br />
              10.1. Організатор забезпечує об'єктивність та неупередженість
              визначення Переможців Розіграшу.
              <br />
              <br />
              10.2. Беручи участь в Розіграші, Учасник підтверджує факт
              ознайомлення з даними Правилами Розіграшу і свою повну та
              безумовну згоду з ними. Порушення Учасником цих Правил або відмова
              Учасника від належного виконання цих Правил (в т.ч. механіки,
              порядку та строків проведення Розіграшу та/або отримання призу,
              тощо) вважається відмовою Учасника від участі в Розіграші та
              отримання призу, при цьому така особа не має права на одержання
              від Організатора будь-якої компенсації. <br />
              <br />
              10.3. Відповідно до вимог ст. 634 Цивільного Кодексу України до
              договорів приєднання, а саме можливості укладання такого договору
              лише шляхом приєднання однієї сторони до запропонованого договору,
              своєю участю в Розіграші всі Учасники Розіграшу погоджуються з
              цими Правилами та зобов'язуються їх виконувати. <br />
              <br />
              <b>11. Застереження</b> <br />
              <br />
              11.1. Будь ласка, щоб уникнути непорозумінь - спочатку читайте
              уважно Правила Розіграшу. <br />
              <br />
              11.2. Просимо прийняти до уваги, що Організатор в будь-якому
              випадку залишає за собою право в будь-який момент припинити
              Розіграш, навіть якщо таке припинення відбудеться до його
              закінчення. У такому випадку Переможець визначатися не буде.
              Інформація про припинення Розіграшу буде публікуватися.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Lottery;
