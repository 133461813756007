import React, { Fragment, useState } from 'react';
import { Link, animateScroll } from 'react-scroll';
import { useEventListener } from '../helpers';
import { Consumer } from '../store';

const Navigation = () => {
  const [isActiveMenu, toggleMobileMenu] = useState(false);

  const handleClick = () => {
    document.body.classList.toggle('no-scroll');
    toggleMobileMenu(!isActiveMenu);
  };

  const scrollToTop = () => animateScroll.scrollToTop();

  useEventListener(
    'scroll',
    e => document.body.className.includes('no-scroll') && e.preventDefault(),
    { passive: false },
    document.body
  );
  useEventListener(
    'touchmove',
    e => document.body.className.includes('no-scroll') && e.preventDefault(),
    { passive: false },
    document.body
  );

  return (
    <Consumer>
      {({ language, library, images, links }) => {
        const { product, benefits, contacts, login } = library[
          language
        ].navigation;

        return (
          <div className="nav">
            <div className="container container--nav">
              <div className="nav-logo">
                <img
                  src={images.logo}
                  className="nav-logo__image"
                  alt="Mr.Tomato"
                  onClick={scrollToTop}
                />
              </div>
              <ul
                className={`nav-menu ${isActiveMenu ? 'nav-menu--active' : ''}`}
              >
                <li className="nav-menu__item">
                  <Link
                    to="product"
                    spy={true}
                    smooth={true}
                    offset={-80}
                    duration={1000}
                    activeClass="nav-menu__link--active"
                    className="nav-menu__link"
                    onClick={handleClick}
                  >
                    {product}
                  </Link>
                </li>
                <li className="nav-menu__item">
                  <Link
                    to="benefits"
                    spy={true}
                    smooth={true}
                    offset={-80}
                    duration={1000}
                    activeClass="nav-menu__link--active"
                    className="nav-menu__link"
                    onClick={handleClick}
                  >
                    {benefits}
                  </Link>
                </li>
                <li className="nav-menu__item">
                  <Link
                    to="contacts"
                    spy={true}
                    smooth={true}
                    offset={-80}
                    duration={1000}
                    activeClass="nav-menu__link--active"
                    className="nav-menu__link nav-menu__link--styled"
                    onClick={handleClick}
                  >
                    {contacts}
                  </Link>
                </li>
              </ul>
              <div className="nav-login">
                {language === 'UA' ? (
                  <a href={links.login} className="nav-login__link">
                    {login}
                  </a>
                ) : null}
              </div>
              <div className="nav-social">
                {language === 'UA' ? (
                  <Fragment>
                    <a
                      href={links.instagram}
                      className="nav-social__link"
                      target="_blank"
                    >
                      <img
                        src={images.social.instagram}
                        className="nav-social__logo"
                        alt="Instagram"
                      />
                    </a>
                    <a
                      href={links.facebook}
                      className="nav-social__link"
                      target="_blank"
                    >
                      <img
                        src={images.social.facebook}
                        className="nav-social__logo"
                        alt="Facebook"
                      />
                    </a>
                  </Fragment>
                ) : null}
              </div>
              <div className="nav-hamburger-wrapper" onClick={handleClick}>
                <div
                  className={`nav-hamburger ${
                    isActiveMenu ? 'nav-hamburger--active' : ''
                  }`}
                />
              </div>
            </div>
          </div>
        );
      }}
    </Consumer>
  );
};

export default Navigation;
