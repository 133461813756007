import React, { Fragment, useState, useEffect } from 'react';
import { MiniTitle } from '../components';
import { useEventListener } from '../helpers';
import { Consumer } from '../store';

const SliderSection = () => {
  const [allowTransform, setAllowTransform] = useState(false);
  const [offset, setOffset] = useState(0);
  const [width, setWidth] = useState(0);

  const wrapper = document.querySelector('.slider');
  const slider = document.querySelector('.slider-body');

  useEffect(() => {
    document.body.offsetWidth >= 1200 && setAllowTransform(true);

    if (allowTransform) {
      setWidth(2625);
      setOffset(wrapper.offsetTop);

      wrapper.style.height = `${width + slider.offsetHeight + 135 * 3}px`;
    }
  });

  useEventListener('scroll', e => {
    if (allowTransform) {
      const scrollY = e.currentTarget.scrollY;

      if (offset - scrollY <= 0 && scrollY - offset <= width) {
        slider.style.transform = `translate3d(${offset - scrollY}px, 0, 0)`;
      }

      if (scrollY - offset > width)
        slider.style.transform = `translate3d(${-width}px, 0, 0)`;
      if (scrollY - offset <= 0)
        slider.style.transform = `translate3d(${0}px, 0, 0)`;
    }
  });

  return (
    <Consumer>
      {({ language, library }) => {
        const { title, demo1, demo2, result } = library[language].slider;

        return (
          <div className="slider">
            <div className="slider-background">
              <div className="container container--slider">
                <MiniTitle
                  classList="slider-title"
                  title={
                    <Fragment>
                      Mr. Tomato{' '}
                      <span className="mini-title--colored">{title[0]}</span>{' '}
                      <br />
                      {title[1]}{' '}
                      <span className="mini-title--colored">{title[2]}</span>{' '}
                      {title[3]}
                    </Fragment>
                  }
                />
                <div className="slider-body">
                  <div className="slider-demo">
                    <div className="slider-item" data-num="1">
                      <div className="slider-item__title">
                        <span className="slider-item__number">20</span>
                        {demo1[0]}
                      </div>
                      <div className="slider-item__description">{demo1[1]}</div>
                    </div>
                    <div className="slider-item" data-num="2">
                      <div className="slider-item__title">
                        <span className="slider-item__number">12-29</span>
                        {demo1[2]}
                      </div>
                      <div className="slider-item__description">{demo1[3]}</div>
                    </div>
                  </div>
                  <div className="slider-demo">
                    <div className="slider-item" data-num="3">
                      <div className="slider-item__title">
                        <span className="slider-item__number">60</span>%
                      </div>
                      <div className="slider-item__description">{demo2[0]}</div>
                    </div>
                    <div className="slider-item" data-num="4">
                      <div className="slider-item__title">
                        <span className="slider-item__number">1-3</span>
                        {demo2[1]}
                      </div>
                      <div className="slider-item__description">{demo2[2]}</div>
                    </div>
                  </div>
                  <div className="slider-result">
                    <div className="slider-description">{result[0]}</div>
                    <div className="slider-description">{result[1]}</div>
                    <div className="slider-item" data-num="1">
                      <div className="slider-item__title">
                        <span className="slider-item__number">20</span>
                        {result[2]}
                      </div>
                      <div className="slider-item__description">
                        {result[3]}
                      </div>
                    </div>
                    <div
                      className="slider-item slider-item--green"
                      data-num="3"
                    >
                      <div className="slider-item__title">
                        <span className="slider-item__number">60</span>%
                      </div>
                      <div className="slider-item__description">
                        {result[4]}
                      </div>
                    </div>
                    <div
                      className="slider-item slider-item--green"
                      data-num="2"
                    >
                      <div className="slider-item__title">
                        <span className="slider-item__number">12-29</span>
                        {result[5]}
                      </div>
                      <div className="slider-item__description">
                        {result[6]}
                      </div>
                    </div>
                    <div className="slider-item" data-num="4">
                      <div className="slider-item__title">
                        <span className="slider-item__number">1-3</span>
                        {result[7]}
                      </div>
                      <div className="slider-item__description">
                        {result[8]}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }}
    </Consumer>
  );
};

export default SliderSection;
