import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './styles/main.scss';

import RImages from './assets/images/restaurants/**.jpg';

class TrialCongratulations extends Component {
  render() {
    return (
      <div className="trial-wrapper">
        <div className="trial">
          <div className="container container--trial">
            <div className="trial-nav">
              <img
                className="trial-nav__logo"
                src={RImages[`logo-small-${this.props.data.id}`]}
                alt={this.props.data.name}
              />
            </div>
            <Link to={`/${this.props.data.route}`}>
              <img
                src={require('./assets/images/Congratulations.png')}
                alt="Congratulations"
              />
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default TrialCongratulations;
