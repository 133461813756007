import React, { Component } from 'react';

export default class WVideo extends Component {
  componentWillMount() {
    const script1 = document.createElement('script');

    script1.src = `https://fast.wistia.com/embed/medias/${
      this.props.src
    }.jsonp`;
    script1.async = true;

    document.body.appendChild(script1);
  }

  render() {
    return (
      <div
        className={`wistia_responsive_padding ${
          this.props.className ? this.props.className : ''
        }`}
      >
        <div className="wistia_responsive_wrapper">
          <div
            className={`wistia_embed wistia_async_${
              this.props.src
            } videoFoam=true`}
          >
            <div className="wistia_swatch">
              <img
                src="https://fast.wistia.com/embed/medias/gy9pl9a8be/swatch"
                className="wistia_video_preview"
                alt="Preview"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
