import React, { Component, Fragment } from "react";
import { Link, Redirect } from "react-router-dom";
import "./styles/main.scss";

import Stuff from "./assets/images/request-stuff-eng.png";
import Manager from "./assets/images/request-manager.png";
import Sanitization from "./assets/images/sanitization.png";
import Check from "./assets/images/request-check-eng.png";

import RImages from "./assets/images/restaurants/**.jpg";

class TrialMenu extends Component {
    render() {
        return (
            <div className="trial-wrapper">
                <div className="trial">
                    <div className="container container--trial">
                        <img
                            className="trial-menu-logo"
                            src={RImages[`logo-big-${this.props.data.id}`]}
                            alt={this.props.data.name}
                        />
                        <ul className="trial-menu-list">
                            {this.props.data.id === 3 ? (
                                <Fragment>
                                    <a
                                        href="https://call.incognize.com/call/NaTHlootNKWuJUQEqIr1SS0Wx1ZAP0/215"
                                        className="trial-menu-list__item"
                                    >
                                        <li>Официант</li>
                                    </a>
                                    <a
                                        href="https://call.incognize.com/call/NaTHlootNKWuJUQEqIr1SS0Wx1ZAP0/216"
                                        className="trial-menu-list__item"
                                    >
                                        <li>Чек</li>
                                    </a>
                                    <a
                                        href="https://call.incognize.com/call/NaTHlootNKWuJUQEqIr1SS0Wx1ZAP0/217"
                                        className="trial-menu-list__item"
                                    >
                                        <li>Кальянщик</li>
                                    </a>
                                    <a
                                        href="https://call.incognize.com/call/NaTHlootNKWuJUQEqIr1SS0Wx1ZAP0/218"
                                        className="trial-menu-list__item"
                                    >
                                        <li>Администратор</li>
                                    </a>
                                </Fragment>
                            ) : (
                                <Fragment>
                                    <Link
                                        to={{
                                            pathname: `/${this.props.data.route}/success`,
                                            state: { target: Sanitization },
                                        }}
                                        className="trial-menu-list__item"
                                    >
                                        <li>Check our sanitization</li>
                                    </Link>
                                    <Link
                                        to={`/${this.props.data.route}/categories`}
                                        className="trial-menu-list__item"
                                    >
                                        <li>View menu</li>
                                    </Link>
                                    <Link
                                        to={{
                                            pathname: `/${this.props.data.route}/success`,
                                            state: { target: Stuff },
                                        }}
                                        className="trial-menu-list__item"
                                    >
                                        <li>Call waiter</li>
                                    </Link>
                                    <Link
                                        to={{
                                            pathname: `/${this.props.data.route}/success`,
                                            state: { target: Check },
                                        }}
                                        className="trial-menu-list__item"
                                    >
                                        <li>Get Check</li>
                                    </Link>
                                </Fragment>
                            )}
                            {/* <Link
                                to={`/${this.props.data.route}/categories`}
                                className="trial-menu-list__item"
                            >
                                <li>View menu</li>
                            </Link> */}
                            {/* <Link
                                to={`/${this.props.data.route}/nps`}
                                className="trial-menu-list__item"
                            >
                                <li>Enter to win</li>
                            </Link>
                             */}
                            <a
                                href="https://nps.mrtomato.io"
                                className="trial-menu-list__item"
                                target="_blank"
                            >
                                <li>Enter to win</li>
                            </a>
                        </ul>
                        <div className="trial-footer">
                            <p className="trial-footer__text">
                                Quality of service provides by
                            </p>
                            <img
                                src={require("./assets/icons/i-logo.svg")}
                                width="131"
                                alt="Mr.Tomato"
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TrialMenu;
