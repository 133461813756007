import React from 'react';
import { Title } from '../components';
import DemoGame from '../components/demo-game/DemoGame';

const Demo = () => {
  return (
    <div className="demo">
      <div className="container container--demo">
        <Title
          classList="demo-title"
          colored="Попробуйте"
          title="сами"
          reverse
        />
        <div className="demo-application">
          <div className="demo-application__phone">
            <div className="demo-application__phone-container">
              <div className="demo-application__phone-screen">
                <img
                  src={require('../assets/images/phone-status-bar.png')}
                  alt=""
                />
                <DemoGame />
              </div>
              <img
                className="demo-application__phone-button"
                src={require('../assets/images/phone-button.png')}
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="demo-stores">
          <a
            href="https://play.google.com/store/apps/details?id=com.trastsoursing.mrtomato"
            className="demo-stores__link"
          >
            <img
              src={require('../assets/images/google.png')}
              alt="Google Play"
            />
          </a>
          <a
            href="https://itunes.apple.com/us/app/mr-tomato/id1451697553?ls=1&mt=8"
            className="demo-stores__link"
          >
            <img src={require('../assets/images/apple.png')} alt="App Store" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Demo;
