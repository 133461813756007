export const QuestionStatus = {
  NEW: 'new',
  PASSED: 'passed',
  REVIEWING: 'reviewing'
};

export const GameStatus = {
  NEW: 'new',
  IN_PROGRESS: 'in_progress',
  FINISH: 'finish'
};

export const QuestionType = {
  SELECT_ONE: 'select_one',
  SELECT_MANY: 'select_many'
};

export const AnswerType = {
  TEXT: 'text',
  IMAGE: 'image'
};
