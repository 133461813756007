import React, { Component } from 'react';
import { QuestionStatus, AnswerType } from '../../helpers/constants';
import answerImages from '../../answers-images/**.jpg';

export default class SelectOne extends Component {
  constructor(props) {
    super(props);

    this.state = {
      answers: this.props.answers,
      correctAnswer: this.props.correctAnswer,
      isReviewing: false
    };
  }

  static getDerivedStateFromProps(props, state) {
    const isReviewing = props.questionStatus === QuestionStatus.REVIEWING;

    if (props.correctAnswer !== state.correctAnswer) {
      return {
        answers: props.answers,
        correctAnswer: props.correctAnswer,
        isReviewing
      };
    }

    return {
      isReviewing
    };
  }

  selectAnswer = id => () => {
    this.setState({
      answers: this.state.answers.map(answer => {
        if (answer.id === id) {
          return {
            ...answer,
            isSelected: true
          };
        }

        return { ...answer, isSelected: false };
      })
    });

    this.props.selectAnswer(id);
  };

  getAnswerClassNames = ({ answer, baseStyles }) => {
    let classNames = `answer ${baseStyles}`;

    // SHIT
    if (this.state.isReviewing) {
      if (answer.id !== this.state.correctAnswer && answer.isSelected) {
        classNames += ' is-wrong';
      }
      if (answer.id === this.state.correctAnswer) {
        classNames += ' is-right';
      }
    } else {
      if (answer.isSelected) {
        classNames += ' is-selected';
      }
    }

    return classNames;
  };

  renderAnswer = answer => {
    let viewResult = '';
    let answerStyles = '';

    switch (this.props.answersType) {
      case AnswerType.IMAGE: {
        viewResult = <img src={answerImages[`answer-${answer.id}`]} alt="" />;
        answerStyles = 'answer__image';
        break;
      }

      case AnswerType.TEXT: {
        viewResult = answer.name;
        answerStyles = 'answer__text';
        break;
      }
    }

    return (
      <button
        className={this.getAnswerClassNames({
          answer,
          baseStyles: answerStyles
        })}
        disabled={this.state.isReviewing}
        key={answer.id}
        onClick={this.selectAnswer(answer.id)}
      >
        {viewResult}
      </button>
    );
  };

  render() {
    const isAnswerSelected = this.state.answers.some(
      answer => answer.isSelected
    );

    return (
      <div className="answer-container">
        {this.state.answers.map(answer => this.renderAnswer(answer))}
      </div>
    );
  }
}
