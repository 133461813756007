import React, { Fragment } from 'react';

const Title = props => {
  return (
    <h1 className={`title ${props.classList || ''}`}>
      {props.reverse ? (
        <Fragment>
          <span className="title--colored">{props.colored}</span> {props.title}
        </Fragment>
      ) : (
        <Fragment>
          {props.title} <span className="title--colored">{props.colored}</span>
        </Fragment>
      )}
    </h1>
  );
}

export default Title;
