import Navigation from './Navigation';
import Demo from './Demo';
import IntroSection from './IntroSection';
import SliderSection from './SliderSection';
import ProcessSection from './ProcessSection';
import VideoSection from './VideoSection';
import ClientsSection from './ClientsSection';
import ContactsSection from './ContactsSection';
import ContactFormSection from './ContactFormSection';
import Footer from './Footer';

export {
  Navigation,
  Demo,
  IntroSection,
  SliderSection,
  ProcessSection,
  VideoSection,
  ClientsSection,
  ContactsSection,
  ContactFormSection,
  Footer
};
