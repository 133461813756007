const restaurants = {
    LKAFA: {
        id: 1,
        name: "L'Kafa Cafe",
        path: "lkafa",
        route: "restaurant",
    },
    EURASIA: {
        id: 2,
        name: "ресторан Евразия",
        path: "eurasia",
        route: "eurasia",
    },
    DUBAI: {
        id: 3,
        name: "ресторан Dubai",
        path: "dubai",
        route: "dubai",
    },
    PANINI: {
        id: 4,
        name: "Panini Kabob Grill",
        path: "panini",
        route: "panini",
    },
};

export default restaurants;
