import React, { Component, Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './styles/main.scss';

const Policy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="policy">
      <div className="container">
        <div className="policy-wrapper">
          <Link to="/">
            <img
              src={require('./assets/icons/i-logo.svg')}
              className="policy-logo"
              alt="Mr.Tomato"
            />
          </Link>
        </div>
        {window.location.origin.includes('.io') ? (
          <Fragment>
            <div className="policy-container">
              <h1 className="policy-title">Політика конфіденційності</h1>
              <p className="policy-text">
                Дана політика конфіденційності персональної інформації (далі -
                Політика) діє відносно всієї інформації, яку власник
                сайту/додатку і/або його афілійовані особи, можуть отримати про
                користувача під час використання ним сайту{' '}
                <a className="policy-link" href="https://mrtomato.io">
                  https://mrtomato.io
                </a>{' '}
                і/або додатку Mr. Tomato.
                <br /> <br />
                Використання сайту сайту{' '}
                <a className="policy-link" href="https://mrtomato.io">
                  https://mrtomato.io
                </a>{' '}
                і/або додатку Mr. Tomato означає беззастережну згоду користувача
                з цією Політикою і зазначеними в ній умовами обробки його
                персональної інформації; в разі незгоди з цими умовами
                користувач повинен утриматися від використання даного
                ресурсу/додатку.
              </p>
              <ul className="policy-list">
                <li className="policy-list__item">
                  1. Персональна інформація користувачів, яку отримує і обробляє
                  сайт{' '}
                  <a className="policy-link" href="https://mrtomato.io">
                    https://mrtomato.io
                  </a>{' '}
                  і/або додаток Mr. Tomato.
                  <ul className="policy-list">
                    <li className="policy-list__item">
                      1.1. В межах цієї Політики під «персональними даними
                      користувача» розуміється:
                      <ul className="policy-list">
                        <li className="policy-list__item">
                          1.1.1. персональна інформація, яку користувач надає
                          про себе самостійно при реєстрації або в іншому
                          процесі використання сайту і/або додатку Mr. Tomato;
                        </li>
                        <li className="policy-list__item">
                          1.1.2 інформація, яка автоматично передається сайтом{' '}
                          <a className="policy-link" href="https://mrtomato.io">
                            https://mrtomato.io
                          </a>{' '}
                          в процесі його використання за допомогою встановленого
                          на пристрої користувача програмного забезпечення, в
                          тому числі IP-адреса, інформація з cookie, інформація
                          про браузер користувача (чи іншої програми, за
                          допомогою якої здійснюється доступ до сайту), час
                          доступу, адреса запитуваної сторінки;
                        </li>
                        <li className="policy-list__item">
                          1.1.3. інформація, яка надається сайту і/або додатку,
                          з метою здійснення надання послуг для відвідувачів
                          сайту/додатку:
                          <ol className="policy-list policy-list--markered">
                            <li className="policy-list__item">
                              електронна пошта;
                            </li>
                            <li className="policy-list__item">прізвище;</li>
                            <li className="policy-list__item">ім'я;</li>
                            <li className="policy-list__item">
                              номер телефону.
                            </li>
                          </ol>
                        </li>
                      </ul>
                    </li>
                    <li className="policy-list__item">
                      1.2. Дана Політика може бути застосована тільки до сайту{' '}
                      <a className="policy-link" href="https://mrtomato.io">
                        https://mrtomato.io
                      </a>{' '}
                      і/або додатку Mr. Tomato і не контролює і не несе
                      відповідальність за сайти третіх осіб, на які користувач
                      може перейти за посиланнями, доступними на сайті{' '}
                      <a className="policy-link" href="https://mrtomato.io">
                        https://mrtomato.io
                      </a>
                      . На таких сайтах у користувача може збиратися або
                      запитуватися інша персональна інформація, а також можуть
                      відбуватися інші дії.
                    </li>
                    <li className="policy-list__item">
                      1.3. Сайт і/або додатку Mr. Tomato не перевіряє
                      достовірність персональної інформації, наданої
                      користувачами, і не здійснює контроль за їх дієздатністю.
                      Однак сайт{' '}
                      <a className="policy-link" href="https://mrtomato.io">
                        https://mrtomato.io
                      </a>{' '}
                      виходить з того, що користувач надає достовірну та
                      достатню персональну інформацію з питань, що пропонуються
                      в формах теперішнього ресурсу, і підтримує цю інформацію в
                      актуальному стані.
                    </li>
                    <li className="policy-list__item">
                      1.4 Сайт і додаток Mr. Tomato використовують аналітичний
                      сервіс, який збирає сторонні файли кукі - Google Analytics{' '}
                      <a
                        className="policy-link"
                        href="https://www.google.com/url?q=https://policies.google.com/technologies/ads?hl%3Duk&sa=D&ust=1556891621468000"
                      >
                        (Політика конфіденційності Google)
                      </a>
                      .
                    </li>
                  </ul>
                </li>
                <li className="policy-list__item">
                  2. Цілі збору і обробки персональної інформації користувачів.
                  <ul className="policy-list">
                    <li className="policy-list__item">
                      2.1. сайт{' '}
                      <a className="policy-link" href="https://mrtomato.io">
                        https://mrtomato.io
                      </a>{' '}
                      та/або додаток Mr. Tomato збирають і зберігають тільки ті
                      персональні дані, що необхідні для надання послуг;
                    </li>
                    <li className="policy-list__item">
                      2.2. персональна інформація користувача може
                      використовуватись в наступних цілях:
                      <ul className="policy-list">
                        <li className="policy-list__item">
                          2.2.1 ідентифікація користувача;
                        </li>
                        <li className="policy-list__item">
                          2.2.2 надання користувачеві персоналізованих послуг і
                          сервісів;
                        </li>
                        <li className="policy-list__item">
                          2.2.3 зв'язок з користувачем, що стосуються
                          використання сайту, надання послуг, а також обробки
                          запитів і заявок від користувача.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li className="policy-list__item">
                  3. Умови обробки персональної інформації користувача і її
                  передачі третім особам.
                  <ul className="policy-list">
                    <li className="policy-list__item">
                      3.1.Забезпечується зберігання конфіденційності
                      персональної інформації користувача, крім випадків
                      добровільного надання користувачем інформації про себе для
                      загального доступу необмеженому колу осіб.
                    </li>
                    <li className="policy-list__item">
                      3.2. Сайт{' '}
                      <a className="policy-link" href="https://mrtomato.io">
                        https://mrtomato.io
                      </a>{' '}
                      і/або додаток Mr. Tomato вправі передати персональну
                      інформацію користувача третім особам в наступних випадках:
                      <ul className="policy-list">
                        <li className="policy-list__item">
                          3.2.1. користувач висловив свою згоду на такі дії;
                        </li>
                        <li className="policy-list__item">
                          3.2.2. передача необхідна в рамках використання
                          користувачем сайту{' '}
                          <a className="policy-link" href="https://mrtomato.io">
                            https://mrtomato.io
                          </a>{' '}
                          і/або додатку Mr. Tomato;
                        </li>
                        <li className="policy-list__item">
                          3.2.3. передача передбачена українським або іншим
                          відповідним законодавством в рамках встановленої
                          законодавством процедури;
                        </li>
                        <li className="policy-list__item">
                          3.2.4. з метою забезпечення можливості захисту прав і
                          законних інтересів сайту{' '}
                          <a className="policy-link" href="https://mrtomato.io">
                            https://mrtomato.io
                          </a>{' '}
                          і/або додатку Mr. Tomato.
                        </li>
                      </ul>
                    </li>
                    <li className="policy-list__item">
                      3.3. При обробці персональних даних користувачів сайт{' '}
                      <a className="policy-link" href="https://mrtomato.io">
                        https://mrtomato.io
                      </a>{' '}
                      і додаток Mr. Tomato керується законом України «Про захист
                      персональних даних».
                    </li>
                  </ul>
                </li>
                <li className="policy-list__item">
                  4. Зміна користувачем персональної інформації.
                  <ul className="policy-list">
                    <li className="policy-list__item">
                      4.1. Користувач може в будь-який момент змінити (оновити,
                      доповнити) надану їм персональну інформацію або її
                      частину, залишивши заяву на адресу адміністрації:{' '}
                      <a
                        className="policy-link"
                        href="mailto:support@mrtomato.io"
                      >
                        support@mrtomato.io
                      </a>
                      .
                    </li>
                    <li className="policy-list__item">
                      4.2. Користувач може в будь-який момент, відкликати свою
                      згоду на обробку персональних даних, залишивши заяву на
                      адресу адміністрації:{' '}
                      <a
                        className="policy-link"
                        href="mailto:support@mrtomato.io"
                      >
                        support@mrtomato.io
                      </a>
                      .
                    </li>
                  </ul>
                </li>
                <li className="policy-list__item">
                  5. Заходи, що застосовуються для захисту персональної
                  інформації користувачів.
                  <ul className="policy-list">
                    <li className="policy-list__item">
                      5.1. Сайт{' '}
                      <a className="policy-link" href="https://mrtomato.io">
                        https://mrtomato.io
                      </a>{' '}
                      і додаток Mr. Tomato приймає необхідні і достатні
                      організаційні та технічні заходи для захисту персональної
                      інформації користувача від неправомірного або випадкового
                      доступу, знищення, копіювання, поширення, а також від
                      інших неправомірних дій з нею третіх осіб.
                    </li>
                  </ul>
                </li>
                <li className="policy-list__item">
                  6. Зміна політики конфіденційності.
                  <ul className="policy-list">
                    <li className="policy-list__item">
                      6.1. Власники сайту{' '}
                      <a className="policy-link" href="https://mrtomato.io">
                        https://mrtomato.io
                      </a>{' '}
                      і додатку Mr. Tomato має право вносити зміни в дану
                      політику конфіденційності. Нова редакція політики вступає
                      в силу з моменту її розміщення, якщо інше не передбачено
                      новою редакцією Політики. Чинна редакція завжди
                      знаходиться на сторінці за адресою:{' '}
                      <a
                        className="policy-link"
                        href="https://mrtomato.io/policy"
                      >
                        https://mrtomato.io/policy
                      </a>
                      .
                    </li>
                  </ul>
                </li>
                <li className="policy-list__item">
                  7. Зворотній зв'язок.
                  <ul className="policy-list">
                    <li className="policy-list__item">
                      7.1. Всі пропозиції або питання з приводу цієї Політики
                      слід направляти на Email:{' '}
                      <a
                        className="policy-link"
                        href="mailto:support@mrtomato.io"
                      >
                        support@mrtomato.io
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="policy-wrapper policy-wrapper--centered">
              <Link to="/" className="policy-button">
                Назад
              </Link>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <div className="policy-container">
              <h1 className="policy-title">Privacy Policy</h1>
              <p className="policy-text">
                This Privacy Policy for Personal Information ("the Policy")
                applies to all information that the site/application owner
                and/or his affiliates may receive from user while using his site{' '}
                <a className="policy-link" href="https://mrtomato.com">
                  https://mrtomato.com
                </a>{' '}
                and/or Mr. Tomato application.
                <br /> <br />
                Using the site{' '}
                <a className="policy-link" href="https://mrtomato.com">
                  https://mrtomato.com
                </a>{' '}
                and/or application Mr. Tomato means unconditional consent of
                user with this Policy and the terms and conditions specified
                during procession of his personal information; in case of
                disagreement with these conditions, user should refrain from
                using this resource/application.
              </p>
              <ul className="policy-list">
                <li className="policy-list__item">
                  1. The personal user information which the site{' '}
                  <a className="policy-link" href="https://mrtomato.com">
                    https://mrtomato.com
                  </a>{' '}
                  and / or Mr. Tomato application receives and processes.
                  <ul className="policy-list">
                    <li className="policy-list__item">
                      1.1. Within this Policy, "personal data of a user" means:
                      <ul className="policy-list">
                        <li className="policy-list__item">
                          1.1.1. personal information provided voluntarily by
                          the user during registration or otherwise while using
                          the site and / or the Mr. Tomato application;
                        </li>
                        <li className="policy-list__item">
                          1.1.2 information that is automatically transmitted by
                          the site{' '}
                          <a
                            className="policy-link"
                            href="https://mrtomato.com"
                          >
                            https://mrtomato.com
                          </a>{' '}
                          during its use with the software installed on the
                          user's device, including IP address, cookie
                          information, user’s browser information (or other
                          program, which is used for site access), access time,
                          address of the requested page;
                        </li>
                        <li className="policy-list__item">
                          1.1.3. information provided to the site and / or
                          application for the purpose of providing services to
                          site / application visitors:
                          <ol className="policy-list policy-list--markered">
                            <li className="policy-list__item">email;</li>
                            <li className="policy-list__item">Last name;</li>
                            <li className="policy-list__item">First name;</li>
                            <li className="policy-list__item">phone number.</li>
                          </ol>
                        </li>
                      </ul>
                    </li>
                    <li className="policy-list__item">
                      1.2. This Policy can only be applied to{' '}
                      <a className="policy-link" href="https://mrtomato.com">
                        https://mrtomato.com
                      </a>{' '}
                      and/or Mr. Tomato application and does not control and is
                      not responsible for third party sites that user may access
                      through the links available at{' '}
                      <a className="policy-link" href="https://mrtomato.com">
                        https://mrtomato.com
                      </a>
                      . Such sites may collect or request other user’s personal
                      information and / or other actions may occur.
                    </li>
                    <li className="policy-list__item">
                      1.3. Website{' '}
                      <a className="policy-link" href="https://mrtomato.com">
                        https://mrtomato.com
                      </a>{' '}
                      and/or Mr. Tomato application does not verify the
                      authenticity of personal information provided by users and
                      does not control their ability to operate. However, the
                      site{' '}
                      <a className="policy-link" href="https://mrtomato.com">
                        https://mrtomato.com
                      </a>{' '}
                      comes from the fact that user provides reliable and
                      sufficient personal information on issues offered in the
                      forms of the current resource, and maintains this
                      information up to date.
                    </li>
                    <li className="policy-list__item">
                      1.4 Website{' '}
                      <a className="policy-link" href="https://mrtomato.com">
                        https://mrtomato.com
                      </a>{' '}
                      and/or Mr. Tomato application uses an analytic service
                      that collects third-party cookies - Google Analytics
                      (Google's Privacy Policy).
                    </li>
                  </ul>
                </li>
                <li className="policy-list__item">
                  2. The goal of personal user information collection and
                  processing.
                  <ul className="policy-list">
                    <li className="policy-list__item">
                      2.1. site{' '}
                      <a className="policy-link" href="https://mrtomato.com">
                        https://mrtomato.com
                      </a>{' '}
                      and/or Mr. Tomato application collects and stores only
                      personal data that is necessary to provide services;
                    </li>
                    <li className="policy-list__item">
                      2.2. User's personal information can be used for the
                      following purposes:
                      <ul className="policy-list">
                        <li className="policy-list__item">
                          2.2.1 user identification;
                        </li>
                        <li className="policy-list__item">
                          2.2.2 providing the user with personalized services;
                        </li>
                        <li className="policy-list__item">
                          2.2.3 communication with the user related to the site
                          using, service providing, as well as processing
                          requests from the user.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li className="policy-list__item">
                  3. Terms of user personal information processing and sharing
                  it with third parties.
                  <ul className="policy-list">
                    <li className="policy-list__item">
                      3.1. The confidentiality of the personal information of
                      the user is ensured, except cases when the user
                      voluntarily provides information about himself for the
                      general access of an unlimited number of persons.
                    </li>
                    <li className="policy-list__item">
                      3.2. Site{' '}
                      <a className="policy-link" href="https://mrtomato.com">
                        https://mrtomato.com
                      </a>{' '}
                      and/or Mr. Tomato application has the right to transfer
                      personal information to third parties in the following
                      cases:
                      <ul className="policy-list">
                        <li className="policy-list__item">
                          3.2.1. the user has expressed his consent to such
                          actions;
                        </li>
                        <li className="policy-list__item">
                          3.2.2. The transfer is required within the framework
                          of the user's use of the site{' '}
                          <a
                            className="policy-link"
                            href="https://mrtomato.com"
                          >
                            https://mrtomato.com
                          </a>{' '}
                          and/or Mr. Tomato application;
                        </li>
                        <li className="policy-list__item">
                          3.3.3. in order to provide the opportunity to protect
                          the rights and legitimate interests of the site{' '}
                          <a
                            className="policy-link"
                            href="https://mrtomato.com"
                          >
                            https://mrtomato.com
                          </a>{' '}
                          and/or Mr. Tomato application.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li className="policy-list__item">
                  4. Change of personal information by the user.
                  <ul className="policy-list">
                    <li className="policy-list__item">
                      4.1. The user may change (update, supplement) the personal
                      information provided by him or her at any time by
                      submitting an application to the administration address:{' '}
                      <a
                        className="policy-link"
                        href="mailto:support@mrtomato.com"
                      >
                        support@mrtomato.com
                      </a>
                      .
                    </li>
                    <li className="policy-list__item">
                      4.2. The user may withdraw his or her consent to the
                      processing of personal data at any time by leaving the
                      application to the address of the administration:{' '}
                      <a
                        className="policy-link"
                        href="mailto:support@mrtomato.com"
                      >
                        support@mrtomato.com
                      </a>
                      .
                    </li>
                  </ul>
                </li>
                <li className="policy-list__item">
                  5. Measures to protect personal information of users.
                  <ul className="policy-list">
                    <li className="policy-list__item">
                      5.1. Site{' '}
                      <a className="policy-link" href="https://mrtomato.com">
                        https://mrtomato.com
                      </a>{' '}
                      and Mr. Tomato application takes the necessary and
                      sufficient organizational and technical measures to
                      protect the user's personal information from unlawful or
                      accidental access, destruction, copying, distribution, and
                      other unlawful actions against it by third parties.
                    </li>
                  </ul>
                </li>
                <li className="policy-list__item">
                  6. Change privacy policy.
                  <ul className="policy-list">
                    <li className="policy-list__item">
                      6.1. The owners of site{' '}
                      <a className="policy-link" href="https://mrtomato.com/">
                        https://mrtomato.com
                      </a>{' '}
                      and/or Mr. Tomato application have the right to make
                      changes to this Privacy Policy. The new edition of the
                      policy will come into force from the moment it is
                      published, unless otherwise provided by the new edition of
                      the Policy. The current version is always available on the
                      following link:{' '}
                      <a
                        className="policy-link"
                        href="https://mrtomato.com/policy"
                      >
                        https://mrtomato.com/policy
                      </a>
                      .
                    </li>
                  </ul>
                </li>
                <li className="policy-list__item">
                  7. Feedback.
                  <ul className="policy-list">
                    <li className="policy-list__item">
                      7.1. All suggestions or questions about this Policy should
                      be sent to Email:{' '}
                      <a
                        className="policy-link"
                        href="mailto:support@mrtomato.com"
                      >
                        support@mrtomato.com
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="policy-wrapper policy-wrapper--centered">
              <Link to="/" className="policy-button">
                Back
              </Link>
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default Policy;
