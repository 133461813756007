import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./styles/main.scss";

import RImages from "./assets/images/restaurants/**.jpg";

class TrialUpload extends Component {
    render() {
        return (
            <div className="trial-wrapper">
                <div className="trial">
                    <div className="container container--trial">
                        <div className="trial-nav">
                            <Link to={`/${this.props.data.route}/upload`}>
                                <img
                                    className="trial-nav__back"
                                    src={require("./assets/icons/i-back.svg")}
                                    alt={this.props.data.name}
                                />
                            </Link>
                            <img
                                className="trial-nav__logo"
                                src={
                                    RImages[`logo-small-${this.props.data.id}`]
                                }
                                alt={this.props.data.name}
                            />
                        </div>
                        <div className="trial-title trial-title--form">
                            Please fill fields <br /> to participate in the draw
                        </div>
                        <div className="trial-form">
                            <input
                                type="text"
                                placeholder="First Name"
                                className="trial-form__input"
                            />
                            <input
                                type="text"
                                placeholder="Last Name"
                                className="trial-form__input"
                            />
                            <input
                                type="text"
                                placeholder="Email"
                                className="trial-form__input"
                            />
                            {/* <input
                type="text"
                placeholder="Phone"
                className="trial-form__input"
              /> */}
                        </div>
                        <Link
                            to={`/${this.props.data.route}/congratulations`}
                            className="trial-form__button"
                        >
                            Submit
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default TrialUpload;
