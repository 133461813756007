import React, { Fragment } from 'react';

const MiniTitle = props => {
  return (
    <h2 className={`mini-title ${props.classList || ''}`}>
      {props.reverse ? (
        <Fragment>
          <span className="mini-title--colored">{props.colored}</span>{' '}
          {props.title}
        </Fragment>
      ) : (
        <Fragment>
          {props.title} {props.removeBR ? null : <br />}{' '}
          {props.removeColored ? null : <span className="mini-title--colored">{props.colored}</span>}
        </Fragment>
      )}
    </h2>
  );
};

export default MiniTitle;
