const information = {
  UA: {
    phoneLink: '+380506534701',
    phoneView: '+38 050 653 47 01',
    email: 'hello@mrtomato.io',
    video: 'gy9pl9a8be'
  },
  EN: {
    phoneLink: '+19492883279',
    phoneView: '+1-949-288-EASY',
    email: 'hello@mrtomato.com',
    video: 'jptt6m809k'
  },
  
};

export default information;
