import React, { Component } from 'react';

export default class AnswersList extends Component {
  render() {
    return (
      <div>
        {this.props.answers.map(answer => (
          <button
            disabled={this.props.availableToAdd}
            onClick={this.props.selectAnswer(answer.id)}
            key={answer.id}
          >
            {answer.name}
            &nbsp;
          </button>
        ))}
      </div>
    );
  }
}
