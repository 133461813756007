import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Title } from '../components';
import { Consumer } from '../store';

const Footer = () => {
  return (
    <Consumer>
      {({ language, library }) => {
        const { title, rights, policy } = library[language].footer;
        return (
          <div className="footer">
            <div className="container container--footer">
              <Title
                classList="footer-title"
                colored={title[0]}
                title={
                  <Fragment>
                    <br /> {title[1]}
                  </Fragment>
                }
                reverse
              />
              <div className="footer-info">
                <span className="footer-info__text footer-info__text--block">
                  {new Date().getFullYear()} Mr. Tomato ©
                </span>
                <span className="footer-info__text">{rights}.</span>
                <span className="footer-info__text footer-info__text--link">
                  <Link to="/policy">{policy}</Link>
                </span>
                <span className="footer-info__text footer-info__text--strong">
                  <a href="https://trustsourcing.com/">
                    Designed & developed by TrustSourcing
                  </a>
                </span>
              </div>
            </div>
          </div>
        );
      }}
    </Consumer>
  );
};

export default Footer;
