import { useState, useEffect, useRef } from 'react';
import 'intersection-observer';

const useIntersection = options => {
  const [intersectionEntry, setEntry] = useState({});
  const element = useRef();
  let flag = false;

  useEffect(() => {
    const observer = new IntersectionObserver((entries, api) => {
      flag = entries[0].isIntersecting;
      flag && api.unobserve(element.current);

      return setEntry(entries[0]);
    }, options);

    observer.observe(element.current);

    return () => observer.disconnect();
  }, [element]);

  return { intersectionEntry, element };
};

export default useIntersection;
