import React from 'react';
import { Form } from '../components';
import { Consumer } from '../store';

const ContactFormSection = () => {
  return (
    <Consumer>
      {props => {
        return (
          <div className="contact-form">
            <div className="container container--contact-form">
              <Form {...props} contactSection autoremove />
            </div>
          </div>
        );
      }}
    </Consumer>
  );
};

export default ContactFormSection;
