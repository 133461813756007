import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './styles/main.scss';

import RImages from './assets/images/restaurants/**.jpg';

class TrialDish extends Component {
  state = {
    data: []
  };

  componentDidMount() {
    axios
      .get(
        'https://mrtomato-qa.dev.trustsourcing.com/api/test/TEST_Denis_GetMenu?restId=450'
      )
      .then(response => this.setState({ data: response.data }));
  }

  render() {
    const category =
      this.state.data &&
      this.state.data.find(a => a.id === +this.props.match.params.categoryId);

    const dish =
      category &&
      category.menuItems.find(b => b.id === +this.props.match.params.dishId);

    return (
      <div className="trial-wrapper">
        <div className="trial">
          <div className="container container--trial-dish">
            {this.props.data.id === 3 ? (
              dish ? (
                <Fragment>
                  <Link
                    to={`/${this.props.data.route}/categories/${+this.props
                      .match.params.categoryId}`}
                    className="trial-dish__back"
                  >
                    <img
                      className="trial-dish__back"
                      src={require('./assets/icons/i-back.svg')}
                      alt={this.props.data.name}
                    />
                  </Link>
                  {dish.image ? (
                    <img
                      className="trial-dish__img"
                      src={`https://mrtomato.live.trustsourcing.com/uploads/${
                        dish.image
                      }`}
                    />
                  ) : (
                    <img
                      className="trial-dish__img"
                      src={require('./assets/images/Dish.png')}
                    />
                  )}
                  <div className="trial-dish">
                    <div className="trial-dish__title">{dish.name}</div>
                    <div className="trial-dish__category">{category.name}</div>
                    <div className="trial-dish__weight">
                      {dish.weight || '-'} гр.
                    </div>
                    <div className="trial-dish__info">
                      <div className="trial-dish__info-item">
                        <div className="trial-dish__value">
                          {dish.price || '-'}
                        </div>
                        <span className="trial-dish__unit">грн</span>
                      </div>
                      <div className="trial-dish__info-item">
                        <div className="trial-dish__value">
                          {dish.cookingTime || '-'}
                        </div>
                        <span className="trial-dish__unit">мин</span>
                      </div>
                      <div className="trial-dish__info-item">
                        <div className="trial-dish__value">
                          {dish.calories || '-'}
                        </div>
                        <span className="trial-dish__unit">ккал</span>
                      </div>
                    </div>
                  </div>
                </Fragment>
              ) : null
            ) : (
              <Link to={`/${this.props.data.route}`}>
                <img src={RImages[`Dish-${this.props.data.id}`]} alt="Dish" />
              </Link>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default TrialDish;
