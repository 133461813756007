const library = {
  UA: {
    navigation: {
      product: 'Про продукт',
      benefits: 'Переваги',
      clients: 'Клієнти',
      contacts: 'Купити',
      login: 'Увійти / Зареєструватися'
    },
    header: {
      title: 'Навчання для покоління',
      colored: 'Instagram',
      subtitle:
        'Інноваційний мобільний навчальний додаток для персоналу ресторанів, який вигідно виділить вас серед конкурентів і збільшить прибуток вашого закладу!',
      button: 'Отримати доступ'
    },
    video: {
      title: 'прискорить навчання персоналу і підвищить рівень засвоєних знань',
      subtitle: 'Надішліть заявку зараз і отримаєте',
      colored: 'безкоштовний доступ'
    },
    slider: {
      title: [
        'прискорить навчання персоналу',
        'та',
        'підвищить рівень',
        'засвоєних знань'
      ],
      demo1: [
        'годин',
        'йде на пошуки нового співробітника',
        'годин',
        'займає навчання 1 співробітника'
      ],
      demo2: [
        'знання меню ресторану',
        'місяців',
        'в середньому новий співробітник працює в ресторані'
      ],
      result: [
        'Mr. Tomato допомагає вашому персоналу вчитися ефективніше, в будь-якому місці, в будь-який час, і без зменшення робочого часу',
        'Mr. Tomato допоможе вашим менеджерам краще контролювати процес підготовки, а значить - підвищити засвоюваність знань і навичок персоналу',
        'годин',
        'йде на пошуки нового співробітника',
        'знання меню ресторану',
        'годин',
        'займає навчання 1 співробітника',
        'місяців',
        'в середньому новий співробітник працює в ресторані'
      ]
    },
    process: {
      title: 'Процес навчання',
      list: [
        'Ви завантажуєте меню ресторану, всі інгредієнти і можливі поєднання',
        'Офіціанти вивчають меню і проходять тести в мобільному додатку',
        'Ваші менеджери регулярно перевіряють успішність кожного співробітника',
        'I звичайно!',
        'Ніколи не забувайте про правила етикету'
      ]
    },
    contacts: {
      title: ['Підключіть свій ресторан', 'вже зараз']
    },
    form: {
      labels: ["Ім'я", 'Електронна пошта', 'Телефон'],
      errors: {
        empty: "Поле обов'язково до заповнення",
        email: 'Потрібна валідна електронна пошта',
        phone: 'Потрібен валідний телефон'
      },
      placeholders: ["Введіть ваше ім'я", 'Введіть ваш email'],
      cookie: 'Я даю згоду на використання моїх персональних даних',
      submit: 'Підключити свій ресторан',
      success: 'Дякуємо за звернення!',
      error: 'Щось пішло не так :(',
      back: 'Повернутися на сайт'
    },
    footer: {
      title: ['Покращуй свій бізнес разом', 'з Mr. Tomato'],
      rights: 'Всі права захищені',
      policy: 'Політика конфіденційності'
    },
    notification: {
      text: [
        'Даний веб-сайт використовує файли cookie з метою забезпечення',
        'максимальної зручності використання'
      ],
      link: 'Подивитися деталі',
      accept: 'Приймаю'
    },
    popup: {
      title: 'Надішліть заявку зараз і отримайте',
      colored: '30 днів безкоштовного доступу'
    }
  },
  EN: {
    navigation: {
      product: 'About',
      benefits: 'Benefits',
      clients: 'Customers',
      contacts: 'Buy',
      login: 'Sign In / Sign Up'
    },
    header: {
      title: 'Learning management system for the',
      colored: 'Instagram generation',
      subtitle:
        'The innovative educational mobile application for the restaurant staff that will make you stand out among the competitors and increase the profit of your restaurant!',
      button: 'Get access'
    },
    video: {
      title: 'helps to prepare staff for advanced restaurant chains in Ukraine',
      subtitle: '',
      colored: ''
    },
    slider: {
      title: [
        'will accelerate staff training',
        'and',
        'increase the level',
        'of acquired knowledge'
      ],
      demo1: [
        'hours',
        'goes for searching a new employee ',
        'hours',
        'takes the training of 1 employee'
      ],
      demo2: [
        'knowledge of the restaurant menu',
        'months',
        'the average working period of a new employee in a restaurant'
      ],
      result: [
        'Mr. Tomato helps your staff learn more efficiently, anywhere, anytime, and without reducing working hours',
        'Mr. Tomato will help your managers to better control the preparation process, which means increase level of staff knowledge and skills',
        'hours',
        'goes for searching a new employee ',
        'knowledge of the restaurant menu',
        'hours',
        'takes the training of 1 employee',
        'months',
        'the average working period of a new employee in a restaurant'
      ]
    },
    process: {
      title: 'Learning process',
      list: [
        'You upload the restaurant menu, all the ingredients, and paired with combinations',
        'Waiters learning the menu and pass tests in the mobile application',
        'Your managers regularly check the learning progress of each employee',
        'Oh, of course!',
        'And never forget the etiquette rules'
      ]
    },
    contacts: {
      title: ['Connect your restaurant', 'right now']
    },
    form: {
      labels: ['Name', 'Email', 'Phone'],
      errors: {
        empty: 'Required field',
        email: 'Need a valid email',
        phone: 'Need a valid phone'
      },
      placeholders: ['Enter your name', 'Enter your email'],
      cookie: 'I agree to the use of my personal data',
      submit: 'Connect your restaurant',
      success: 'Thank you for your request!',
      error: 'Something went wrong :(',
      back: 'Back to website'
    },
    footer: {
      title: ['Improve your business', 'with Mr. Tomato'],
      rights: 'All rights reserved',
      policy: 'Privacy policy'
    },
    notification: {
      text: [
        'This website uses cookies to ensure you get',
        'the best experience on our website'
      ],
      link: 'Learn more',
      accept: 'I accept'
    },
    popup: {
      title: 'Leave your contact details',
      colored: 'and get 30 days free test'
    }
  }
};



export default library;
