const images = {
  logo: require('../assets/icons/i-logo.svg'),
  social: {
    facebook: require('../assets/icons/i-fb.svg'),
    instagram: require('../assets/icons/i-instagram.svg')
  },
  intro: {
    header: require('../assets/images/header-tomato.png'),
    UA: {
      background: require('../assets/images/header-bg-UA.png'),
      phone: require('../assets/images/header-phones-UA.png')
    },
    EN: {
      background: require('../assets/images/header-bg-EN.png'),
      phone: require('../assets/images/header-phones-EN.png')
    }
  },
  video: {
    googlePlay: require('../assets/images/google.png'),
    appStore: require('../assets/images/apple.png')
  },
  slider: {
    UA: [
      require(`../assets/images/process-phone-UA-1.png`),
      require(`../assets/images/process-phone-UA-2.png`),
      require(`../assets/images/process-phone-UA-3.png`),
      require(`../assets/images/process-phone-UA-4.png`)
    ],
    EN: [
      require(`../assets/images/process-phone-EN-1.png`),
      require(`../assets/images/process-phone-EN-2.png`),
      require(`../assets/images/process-phone-EN-3.png`),
      require(`../assets/images/process-phone-EN-4.png`)
    ]
  },
  clients: [
    require(`../assets/images/Eurasia.png`),
    require(`../assets/images/Alchemist.jpg`),
    require(`../assets/images/Vino.png`)
  ],
  partners: [
    require(`../assets/images/RestoPraktiki.png`),
    require(`../assets/images/Horeca.png`),
    ''
  ]
};

export default images;
