import React, { Fragment } from 'react';
import { MiniTitle, WVideo } from '../components';
import { Consumer } from '../store';

const VideoSection = () => {
  return (
    <Consumer>
      {({ language, library, information, images, links }) => {
        const { title, subtitle, colored } = library[language].video;
        const { googlePlay, appStore } = images.video;

        return (
          <div className="video">
            <div className="container container--video">
              <MiniTitle
                classList="video-title"
                colored="Mr. Tomato"
                title={title}
                reverse
              />
              <WVideo
                src={information[language].video}
                className="video-media"
              />
              {subtitle ? (
                <MiniTitle
                  classList="video-second-title"
                  title={subtitle}
                  colored={colored}
                />
              ) : null}
              {language === 'UA' ? (
                <div className="video-stores">
                  <a href={links.googlePlay} className="video-stores__link">
                    <img src={googlePlay} alt="Google Play" />
                  </a>
                  <a href={links.appStore} className="video-stores__link">
                    <img src={appStore} alt="App Store" />
                  </a>
                </div>
              ) : null}
            </div>
          </div>
        );
      }}
    </Consumer>
  );
};

export default VideoSection;
