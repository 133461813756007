import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { Element } from 'react-scroll';
import {
  Navigation,
  IntroSection,
  SliderSection,
  ProcessSection,
  VideoSection,
  ClientsSection,
  ContactsSection,
  ContactFormSection,
  Footer
} from './sections';
import { Notification, Popup, Portal } from './components';
import { Provider } from './store';
import './styles/main.scss';
import { library, images, links, information } from './helpers';

class App extends Component {
  state = {
    notificationShown: false,
    popupShown: false
  };

  componentDidMount() {
    localStorage.getItem('tomatoLandingCookie')
      ? null
      : this.setState({ notificationShown: true });

    setTimeout(() => {
      if (!localStorage.getItem('popupNotNeeded')) {
        this.setState({ popupShown: true });
      }
    }, 60000);
  }

  shouldComponentUpdate(nextProps, nextState) {
    nextState.popupShown
      ? document.body.classList.add('no-scroll--desktop')
      : document.body.classList.remove('no-scroll--desktop');

    return true;
  }

  setNotificationCookie = () => {
    localStorage.setItem('tomatoLandingCookie', true);
    this.setState({ notificationShown: false });
  };

  closePopup = () => this.setState({ popupShown: false });


  render() {
    const isUA = window.location.origin.includes('.io');

    return (
      <Provider
        value={{
          library,
          images,
          links,
          information,
          language: isUA ? 'UA' : 'EN'
        }}
      >
        {isUA ? (
          <Helmet>
            <title>Mr. Tomato - Навчання для покоління Instagram</title>
            <script
              async
              src="https://www.googletagmanager.com/gtag/js?id=UA-134646044-1"
            />
            <script>
              {`window.dataLayer = window.dataLayer || [];
                  function gtag() {
                    dataLayer.push(arguments);
                  }
                  gtag('js', new Date());

                  gtag('config', 'UA-134646044-1');`}
            </script>
          </Helmet>
        ) : (
          <Helmet>
            <title>
              Mr. Tomato - Learning management system for generation of
              Instagram
            </title>
            <script
              async
              src="https://www.googletagmanager.com/gtag/js?id=UA-134646044-3"
            />
            <script>
              {`window.dataLayer = window.dataLayer || [];
                  function gtag() {
                    dataLayer.push(arguments);
                  }
                  gtag('js', new Date());

                  gtag('config', 'UA-134646044-3');`}
            </script>
          </Helmet>
        )}
        <Navigation />
        <Element name="product">
          <IntroSection />
          <VideoSection />
        </Element>
        <Element name="benefits">
          <SliderSection />
          <ProcessSection />
          {isUA ? <ClientsSection /> : null}
          <ContactsSection />
        </Element>
        <Element name="contacts">
          <ContactFormSection />
          <Footer />
        </Element>
        <Portal>
          {this.state.notificationShown && (
            <Notification setCookie={this.setNotificationCookie} />
          )}
        </Portal>
        <Portal>
          {this.state.popupShown && <Popup close={this.closePopup} />}
        </Portal>
      </Provider>
    );
  }
}

export default App;
