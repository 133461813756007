import React from 'react';
import { Link } from 'react-scroll';
import { Title } from '../components';
import { Consumer } from '../store';

const IntroSection = () => {
  return (
    <Consumer>
      {({ language, library, images }) => {
        const { title, colored, subtitle, button } = library[language].header;

        return (
          <div className="header">
            <div className="container container--header">
              <div className="header-content">
                <Title
                  classList="header-content__title"
                  title={title}
                  colored={colored}
                />
                <p className="header-content__subtitle">{subtitle}</p>
                <Link
                  to="contacts"
                  spy={true}
                  smooth={true}
                  offset={-80}
                  duration={1000}
                  className="header-content__button"
                >
                  {button}
                </Link>
                <img
                  src={images.intro.header}
                  className="header-content__tomato"
                  alt="MrTomato"
                />
              </div>
              <div className="header-media">
                <div
                  className="header-media__bg"
                  style={{
                    backgroundImage: `url(${images.intro[language].background})`
                  }}
                />
                <img
                  src={images.intro[language].phone}
                  className="header-media__image"
                  alt="Tomato App"
                />
              </div>
            </div>
          </div>
        );
      }}
    </Consumer>
  );
};

export default IntroSection;
