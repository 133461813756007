import React, { Fragment } from 'react';
import { Link } from 'react-scroll';
import { Title } from '../components';
import { Consumer } from '../store';

const ClientsSection = () => {
  return (
    <Consumer>
      {({ images }) => {
        <Fragment>
          <div className="clients">
            <div className="container container--clients">
              <div className="clients-header">
                <Title
                  title="Наші"
                  colored="Клієнти"
                  reversed
                  classList="clients__title"
                />
                <Link
                  to="contacts"
                  spy={true}
                  smooth={true}
                  offset={-80}
                  duration={1000}
                  className="clients__link"
                >
                  + Стати клієнтом
                </Link>
              </div>
              <div className="clients-list">
                {images.clients.map((img, index) => (
                  <div
                    key={index}
                    className="clients-list__item"
                    style={{ backgroundImage: `url(${img})` }}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="clients">
            <div className="container container--clients">
              <div className="clients-header">
                <Title
                  title="Наші"
                  colored="Партнери"
                  reversed
                  classList="clients__title"
                />
                <Link
                  to="contacts"
                  spy={true}
                  smooth={true}
                  offset={-80}
                  duration={1000}
                  className="clients__link"
                >
                  + Стати партнером
                </Link>
              </div>
              <div className="clients-list">
                {images.partners.map((img, index) => (
                  <div
                    key={index}
                    className="clients-list__item"
                    style={{ backgroundImage: `url(${img})` }}
                  />
                ))}
              </div>
            </div>
          </div>
        </Fragment>;
      }}
    </Consumer>
  );
};

export default ClientsSection;
