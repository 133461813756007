import React, { useState } from 'react';
import axios from 'axios';
import MaskedInput from 'react-text-mask';
import { Formik, Field, Form as FormikForm, ErrorMessage } from 'formik';

const Form = ({
  language,
  library,
  contactSection,
  needButton,
  autoremove,
  closePopup
}) => {
  const [errorMessage, showErrorMessage] = useState(false);
  const [successMessage, showSuccessMessage] = useState(false);
  const [agreeError, setAgreeError] = useState(false);
  const [isAgree, toggleCheckbox] = useState(false);

  const {
    errors,
    labels,
    placeholders,
    cookie,
    submit,
    success,
    back
  } = library[language].form;

  return (
    <Formik
      initialValues={{
        name: '',
        email: '',
        phone: ''
      }}
      validate={values => {
        let errorObj = {};

        Object.keys(values).map(key => {
          if (!values[key]) {
            errorObj[key] = errors.empty;
          } else if (key === 'email') {
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values[key])
              ? (errorObj[key] = errors.email)
              : null;
          } else if (key === 'phone' && values[key] === '(___) ___ __ __') {
            errorObj[key] = errors.phone;
          }
        });

        return errors;
      }}
      onSubmit={({ name, email, phone }, { resetForm }) => {
        if (isAgree) {
          localStorage.setItem('popupNotNeeded', true);
          axios
            .get(`/subscribe?email=${email}&name=${name}&phone=${phone}`)
            .then(response => {
              toggleCheckbox(false);
              showSuccessMessage(true);
              resetForm();
              autoremove && setTimeout(() => showSuccessMessage(false), 3000);
            })
            .catch(error => {
              showErrorMessage(error);
            });
        } else {
          setAgreeError(true);
        }
      }}
    >
      {() => (
        <FormikForm
          className={`form ${contactSection ? 'form--contact-section' : ''} ${
            successMessage ? 'form--success' : ''
          }`}
        >
          <div className="form__field">
            <label htmlFor="c_name" className="form__label">
              {labels[0]}
            </label>
            <Field
              type="text"
              id="c_name"
              name="name"
              placeholder={placeholders[0]}
              className="form__input"
            />
            <ErrorMessage className="form__error" name="name" component="div" />
          </div>
          <div className="form__field">
            <label htmlFor="c_mail" className="form__label">
              {labels[1]}
            </label>
            <Field
              type="email"
              id="c_mail"
              name="email"
              className="form__input"
              placeholder={placeholders[1]}
            />
            <ErrorMessage
              className="form__error"
              name="email"
              component="div"
            />
          </div>
          <div className="form__field">
            <label htmlFor="c_phone" className="form__label">
              {labels[2]}
            </label>
            <Field
              type="text"
              id="c_phone"
              name="phone"
              render={({ field }) => {
                return (
                  <MaskedInput
                    mask={[
                      '(',
                      /\d/,
                      /\d/,
                      /\d/,
                      ')',
                      ' ',
                      /\d/,
                      /\d/,
                      /\d/,
                      ' ',
                      /\d/,
                      /\d/,
                      ' ',
                      /\d/,
                      /\d/
                    ]}
                    className="form__input"
                    placeholder="(---) --- -- --"
                    {...field}
                  />
                );
              }}
            />
            <ErrorMessage
              className="form__error"
              name="phone"
              component="div"
            />
          </div>
          <div
            className="form__field form__field--checkbox"
            onClick={() => toggleCheckbox(!isAgree)}
          >
            <div
              className={`form__checkbox ${
                isAgree ? 'form__checkbox--checked' : ''
              } ${agreeError ? 'form__checkbox--error' : ''}`}
            />
            <label
              htmlFor="c_isAgree"
              className={`form__label form__label--checkbox ${
                agreeError ? 'form__label--error' : ''
              }`}
            >
              {cookie}
            </label>
          </div>
          <button type="submit" className="form__button">
            {submit}
          </button>
          <div className="form__error-message">{errorMessage}</div>
          <div
            className={`form__success-message ${
              successMessage ? 'form__success-message--visible' : ''
            }`}
          >
            <div className="form__success-wrapper">
              <div className="form__success-title">{success}</div>
              <img
                src={require('../assets/images/success.png')}
                alt="Success"
                className="form__success-img"
              />
              {needButton ? (
                <div className="form__button" onClick={closePopup}>
                  {back}
                </div>
              ) : null}
            </div>
          </div>
        </FormikForm>
      )}
    </Formik>
  );
};

export default Form;
