import React from 'react';
import MiniTitle from './MiniTitle';
import Form from './Form';
import { Consumer } from '../store';

const Popup = props => {
  const { close } = props;

  return (
    <Consumer>
      {({ language, library }) => {
        const { title, colored } = library[language].popup;

        return (
          <div className="popup-wrapper">
            <div className="popup">
              <span className="popup__close" onClick={close}>
                &times;
              </span>
              <div className="popup__text">
                <MiniTitle
                  classList="popup__text-item"
                  title={title}
                  removeBR
                  removeColored
                />
                <MiniTitle
                  classList="popup__text-item"
                  colored={colored}
                  removeBR
                />
              </div>
              <div className="popup__form">
                <Form
                  language={language}
                  library={library}
                  closePopup={close}
                  needButton
                />
              </div>
            </div>
          </div>
        );
      }}
    </Consumer>
  );
};

export default Popup;
