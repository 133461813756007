import 'babel-polyfill';
import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import App from './App';
import Policy from './Policy';
import Lottery from './Lottery';

import TrialMenu from './TrialMenu';
import TrialRequest from './TrialRequest';
import TrialCategories from './TrialCategories';
import TrialNextCategory from './TrialNextCategory';
import TrialDish from './TrialDish';
import TrialNPS from './TrialNPS';
import TrialUpload from './TrialUpload';
import TrialForm from './TrialForm';
import TrialCongratulations from './TrialCongratulations';

import restaurants from './helpers/restaurants';

const { LKAFA, EURASIA, DUBAI } = restaurants;

document.cookie =
  '.AspNetCore.Identity.Application=CfDJ8Am1n4TLcYFJjMFFJrEcz7mfr1EWWERt3NP_AqV2L94Z_JJCuGnNrzk_kI6z13BGjtUp4yJBaQrbtzW7rvOJEe0PfLTya1OJ9Y8WDmFKyvH7Lfwfykta4EZfttM7kF5pKHRFxP92RALI7ldRjpqAplMgdGt8FHE4ZZODKU';

ReactDOM.render(
  <Router>
    <Fragment>
      <Route exact path="/" component={App} />
      <Route path="/policy" component={Policy} />
      <Route path="/lotteryrules" component={Lottery} />

      {/* lkafa */}
      <Route
        exact
        path="/restaurant"
        component={props => <TrialMenu data={LKAFA} {...props} />}
      />
      <Route
        path="/restaurant/success"
        component={props => <TrialRequest data={LKAFA} {...props} />}
      />
      <Route
        path="/restaurant/nps"
        component={props => <TrialNPS data={LKAFA} {...props} />}
      />
      <Route
        path="/restaurant/upload"
        component={props => <TrialUpload data={LKAFA} {...props} />}
      />
      <Route
        path="/restaurant/form"
        component={props => <TrialForm data={LKAFA} {...props} />}
      />
      <Route
        path="/restaurant/congratulations"
        component={props => <TrialCongratulations data={LKAFA} {...props} />}
      />
      <Route
        exact
        path="/restaurant/categories"
        component={props => <TrialCategories data={LKAFA} {...props} />}
      />
      <Route
        exact
        path="/restaurant/categories/next-category"
        component={props => <TrialNextCategory data={LKAFA} {...props} />}
      />
      <Route
        path="/restaurant/categories/next-category/dish"
        component={props => <TrialDish data={LKAFA} {...props} />}
      />

      {/* eurasia */}
      <Route
        exact
        path="/eurasia"
        component={props => <TrialMenu data={EURASIA} {...props} />}
      />
      <Route
        path="/eurasia/success"
        component={props => <TrialRequest data={EURASIA} {...props} />}
      />
      <Route
        path="/eurasia/nps"
        component={props => <TrialNPS data={EURASIA} {...props} />}
      />
      <Route
        path="/eurasia/upload"
        component={props => <TrialUpload data={EURASIA} {...props} />}
      />
      <Route
        path="/eurasia/form"
        component={props => <TrialForm data={EURASIA} {...props} />}
      />
      <Route
        path="/eurasia/congratulations"
        component={props => <TrialCongratulations data={EURASIA} {...props} />}
      />
      <Route
        exact
        path="/eurasia/categories"
        component={props => <TrialCategories data={EURASIA} {...props} />}
      />
      <Route
        exact
        path="/eurasia/categories/next-category"
        component={props => <TrialNextCategory data={EURASIA} {...props} />}
      />
      <Route
        path="/eurasia/categories/next-category/dish"
        component={props => <TrialDish data={EURASIA} {...props} />}
      />

      {/* dubai */}
      <Route
        exact
        path="/dubai"
        component={props => <TrialMenu data={DUBAI} {...props} />}
      />
      <Route
        path="/dubai/success"
        component={props => <TrialRequest data={DUBAI} {...props} />}
      />
      <Route
        path="/dubai/nps"
        component={props => <TrialNPS data={DUBAI} {...props} />}
      />
      <Route
        path="/dubai/upload"
        component={props => <TrialUpload data={DUBAI} />}
      />
      <Route
        path="/dubai/form"
        component={props => <TrialForm data={DUBAI} {...props} />}
      />
      <Route
        path="/dubai/congratulations"
        component={props => <TrialCongratulations data={DUBAI} {...props} />}
      />
      <Route
        exact
        path="/dubai/categories"
        component={props => <TrialCategories data={DUBAI} {...props} />}
      />
      <Route
        exact
        path="/dubai/categories/:categoryId"
        component={props => <TrialNextCategory data={DUBAI} {...props} />}
      />
      <Route
        path="/dubai/categories/:categoryId/:dishId"
        component={props => <TrialDish data={DUBAI} {...props} />}
      />
    </Fragment>
  </Router>,
  document.getElementById('root')
);
