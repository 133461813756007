import React, { Component } from 'react';

export default class StartScreenTest extends Component {
  constructor(props) {
    super(props);

    this.svgCanvas = React.createRef();
    this.startTime = null;
  }

  // rgba(19, 178, 101, 0.2)
  render() {
    return (
      <div>
        <p className="game-title">Начать тестовую версию</p>
        <svg
          viewBox="0 0 340 340"
          xmlns="http://www.w3.org/2000/svg"
          className="start-canvas"
          ref={this.svgCanvas}
        >
          <g
            transform="translate(170,170)"
            style={{ filter: 'url("#gooeyCodeFilter")' }}
          >
              <circle cx="0" cy="0" r="160" fill="#13b265" opacity="0.05" />
              <circle cx="0" cy="0" r="125" fill="#13b265" opacity="0.1" />
            <g className="start-circle">
              <circle
                className="test-circle-1"
                r="8"
                fill="#15CA72"
                cx="0"
                cy="0"
              />
              <circle
                className="test-circle-2"
                cx="0"
                cy="0"
                r="5"
                fill="#15CA72"
              />
              <circle
                className="test-circle-3"
                cx="0"
                cy="0"
                r="8"
                fill="#15CA72"
              />

              <circle
                className="test-circle-4"
                cx="0"
                cy="0"
                r="4"
                fill="#15CA72"
              />
              <circle
                className="test-circle-5"
                cx="0"
                cy="0"
                r="3"
                fill="#15CA72"
              />
              <circle
                className="test-circle-6"
                cx="0"
                cy="0"
                r="6"
                fill="#F05540"
              />
              <circle
                className="test-circle-7"
                cx="0"
                cy="0"
                r="2"
                fill="#F05540"
              />
              <g className="start-main-circle" onClick={this.props.startGame}>
                <circle
                  cx="0"
                  cy="0"
                  r="95"
                  fill="#16CA72"
                  className="start-click-container"
                />
                <text
                  className="start-text"
                  x="0"
                  y="0"
                  alignmentBaseline="middle"
                  textAnchor="middle"
                >
                  Старт
                </text>
              </g>
            </g>
          </g>

          <defs>
            <filter id="gooeyCodeFilter">
              <feGaussianBlur
                in="SourceGraphic"
                stdDeviation="10"
                colorInterpolationFilters="sRGB"
                result="blur"
              />
              <feColorMatrix
                in="blur"
                mode="matrix"
                values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9"
                result="gooey"
              />
              <feBlend in="SourceGraphic" in2="gooey" />
            </filter>
          </defs>
        </svg>
      </div>
    );
  }
}

function getBBox(element, withoutTransforms, toElement) {
  var svg = element.ownerSVGElement;

  if (!svg) {
    return { x: 0, y: 0, width: 0, height: 0 };
  }

  if (withoutTransforms) {
    return element.getBBox();
  }

  var p = svg.createSVGPoint();
  var r = element.getBBox();

  var matrix = (toElement || svg)
    .getScreenCTM()
    .inverse()
    .multiply(element.getScreenCTM());

  p.x = r.x;
  p.y = r.y;
  var a = p.matrixTransform(matrix);

  p.x = r.x + r.width;
  p.y = r.y;
  var b = p.matrixTransform(matrix);

  p.x = r.x + r.width;
  p.y = r.y + r.height;
  var c = p.matrixTransform(matrix);

  p.x = r.x;
  p.y = r.y + r.height;
  var d = p.matrixTransform(matrix);

  var minX = Math.min(a.x, b.x, c.x, d.x);
  var maxX = Math.max(a.x, b.x, c.x, d.x);
  var minY = Math.min(a.y, b.y, c.y, d.y);
  var maxY = Math.max(a.y, b.y, c.y, d.y);

  var width = maxX - minX;
  var height = maxY - minY;

  return {
    x: minX,
    y: minY,
    width: width,
    height: height,
    cx: minX + width / 2,
    cy: minY + height / 2
  };
}
