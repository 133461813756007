import React, { Component } from 'react';
import effect from './effect.mp3';

export default class StartScreen extends Component {
  constructor(props) {
    super(props);

    this.audioFile = new Audio(effect);
    this.audioFile.addEventListener(
      'ended',
      function() {
        this.currentTime = 0;
        this.play();
      },
      false
    );
  }

  hoverStart = () => {
    this.audioFile.play();
  };

  hoverEnd = () => {
    this.audioFile.pause();
  };

  render() {
    return (
      <svg
        viewBox="0 0 340 340"
        xmlns="http://www.w3.org/2000/svg"
        className="start-canvas"
      >
        <g transform="translate(170,170)">
            <circle cx="0" cy="0" r="160" fill="#13b265" opacity="0.05" />
            <circle cx="0" cy="0" r="125" fill="#13b265" opacity="0.1" />
          <g
            className="start-circle"
            onMouseOver={this.hoverStart}
            onMouseLeave={this.hoverEnd}
          >
            <g className="start-main-circle">
              <circle cx="0" cy="0" r="95" fill="#16CA72" />
              <text
                className="start-text"
                x="0"
                y="0"
                alignmentBaseline="middle"
                textAnchor="middle"
              >
                Старт
              </text>
            </g>
            <circle
              className="circle-1"
              cx={-50}
              cy={152}
              r="5"
              fill="#15CA72"
            />
            <circle
              className="circle-2"
              cx={-70}
              cy={-145}
              r="5"
              fill="#15CA72"
            />
            <circle
              className="circle-3"
              cx={130}
              cy={-90}
              r="8"
              fill="#15CA72"
            />

            <circle className="circle-4" cx={90} cy={86} r="4" fill="#15CA72" />
            <circle
              className="circle-5"
              cx={-110}
              cy={-58}
              r="3"
              fill="#15CA72"
            />
            <circle
              className="circle-6"
              cx={-80}
              cy={95}
              r="6"
              fill="#F05540"
            />
            <circle
              className="circle-7"
              cx={120}
              cy={-35}
              r="2"
              fill="#F05540"
            />
          </g>
        </g>
      </svg>
    );
  }
}
