import React, { Fragment } from 'react';
import { Title } from '../components';
import { Consumer } from '../store';

const ContactsSection = () => {
  return (
    <Consumer>
      {({ language, library, information }) => {
        const { title } = library[language].contacts;

        return (
          <div className="contacts">
            <Title
              classList="contacts-title"
              colored={title[0]}
              title={
                <Fragment>
                  <br /> {title[1]}
                </Fragment>
              }
              reverse
            />
            <div className="contacts-info">
              <div className="container container--contacts">
                <span className="contacts-info__phone">
                  <a href={`tel:${information[language].phoneLink}`}>
                    {information[language].phoneView}
                  </a>
                </span>
                <span className="contacts-info__mail">
                  <a href={`mailto:${information[language].email}`}>
                    {information[language].email}
                  </a>
                </span>
              </div>
            </div>
          </div>
        );
      }}
    </Consumer>
  );
};

export default ContactsSection;
