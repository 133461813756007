import React, { useState, useEffect } from 'react';
import { Title } from '../components';
import { useEventListener } from '../helpers';
import { Consumer } from '../store';

const ProcessSection = () => {
  const [allowTransform, setAllowTransform] = useState(false);
  const [activeSlide, setActiveSlide] = useState(0);
  const [lineHeight, setLineHeight] = useState(0);
  const [offset, setOffset] = useState(0);
  const [height, setHeight] = useState(0);

  const container = document.querySelector('.container--process');
  const line = document.querySelector('.process-list__line');
  const activeLine = document.querySelector('.process-list__line-green');

  useEffect(() => {
    document.body.offsetWidth >= 1200 && setAllowTransform(true);

    if (allowTransform) {
      setOffset(container.offsetTop);
      setHeight(line.height.baseVal.value * 8);
      container.style.height = `${height - 67 * 8}px`;
    }
  });

  useEventListener('scroll', e => {
    if (allowTransform) {
      const scrollY = e.currentTarget.scrollY - 67;

      if (scrollY - offset >= 0 && scrollY - offset <= height) {
        setLineHeight((scrollY - offset) / 4);
        activeLine.setAttribute('height', `${lineHeight}px`);
      }

      if (scrollY - offset > height)
        activeLine.setAttribute('height', `${height}px`);
      if (scrollY - offset <= 0) activeLine.setAttribute('height', '0px');

      if (lineHeight < 140) {
        setActiveSlide(0);
      } else if (lineHeight >= 140 && lineHeight < 280) {
        setActiveSlide(1);
      } else if (lineHeight >= 280 && lineHeight < 419) {
        setActiveSlide(2);
      } else if (lineHeight >= 419) {
        setActiveSlide(3);
      }
    }
  });

  return (
    <Consumer>
      {({ language, library, images }) => {
        const { title, list } = library[language].process;

        return (
          <div className="process">
            <div className="container container--process">
              <Title classList="process-title" title={title} />
              <div className="process-wrapper">
                <div className="process-media">
                  <img
                    src={images.slider[language][activeSlide]}
                    alt="Process"
                    className="process-media__img"
                  />
                </div>
                <ul className="process-list">
                  <svg
                    width="22"
                    height="444"
                    viewBox="0 0 22 444"
                    xmlns="http://www.w3.org/2000/svg"
                    className="process-list__line"
                  >
                    <g>
                      <line
                        x1="1"
                        y1="-1"
                        x2="417.001"
                        y2="-1"
                        transform="matrix(-0.00239234 0.999997 -1 0 11 11)"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                      <circle cx="11" cy="11" r="10" />
                      <circle cx="11" cy="150" r="10" />
                      <circle cx="11" cy="290" r="10" />
                      <circle cx="11" cy="429" r="10" />
                    </g>

                    <mask id="line">
                      <line
                        x1="1"
                        y1="-1"
                        x2="417.001"
                        y2="-1"
                        transform="matrix(-0.00239234 0.999997 -1 0 11 11)"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                      <circle cx="11" cy="11" r="10" />
                      <circle cx="11" cy="150" r="10" />
                      <circle cx="11" cy="290" r="10" />
                      <circle cx="11" cy="429" r="10" />
                    </mask>

                    <rect
                      x="0"
                      y="0"
                      width="22"
                      height="0"
                      className="process-list__line-green"
                      mask="url(#line)"
                    />
                  </svg>
                  <li className="process-list__item">{list[0]}</li>
                  <li className="process-list__item">{list[1]}</li>
                  <li className="process-list__item">{list[2]}</li>
                  <li className="process-list__item">
                    {list[3]}
                    <br />
                    {list[4]}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        );
      }}
    </Consumer>
  );
};

export default ProcessSection;
