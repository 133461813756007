import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Consumer } from '../store';

export default class Notification extends Component {
  render() {
    const { setCookie } = this.props;

    return (
      <Consumer>
        {({ language, library }) => {
          const { text, accept, link } = library[language].notification;

          return (
            <div className="notification">
              <div className="notification__text">
                {text[0]} <br />
                {text[1]}. <br />
                <Link to="/policy" className="notification__link">
                  {link}.
                </Link>
              </div>
              <div className="notification__btn" onClick={setCookie}>
                {accept}
              </div>
            </div>
          );
        }}
      </Consumer>
    );
  }
}
