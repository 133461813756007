import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './styles/main.scss';

import RImages from './assets/images/restaurants/**.jpg';

class TrialNextCategory extends Component {
  state = {
    data: []
  };

  componentDidMount() {
    axios
      .get(
        'https://mrtomato-qa.dev.trustsourcing.com/api/test/TEST_Denis_GetMenu?restId=450'
      )
      .then(response => this.setState({ data: response.data }));
  }

  render() {
    const category = this.state.data.find(
      a => a.id === +this.props.match.params.categoryId
    );

    return (
      <div className="trial-wrapper">
        <div className="trial">
          <div className="container container--trial">
            <div className="trial-nav">
              <Link to={`/${this.props.data.route}/categories`}>
                <img
                  className="trial-nav__back"
                  src={require('./assets/icons/i-back.svg')}
                  alt={this.props.data.name}
                />
              </Link>
              <img
                className="trial-nav__logo"
                src={RImages[`logo-small-${this.props.data.id}`]}
                alt={this.props.data.name}
              />
            </div>
            {this.props.data.id === 3 ? (
              category ? (
                <div className="trial-grid">
                  {category.menuItems &&
                    category.menuItems.map(item => {
                      const url = `https://mrtomato.live.trustsourcing.com/uploads/${
                        item.image
                      }`;

                      return (
                        <Link
                          to={`/${this.props.data.route}/categories/${+this
                            .props.match.params.categoryId}/${item.id}`}
                          style={
                            item.image && { backgroundImage: `url(${url})` }
                          }
                          className="trial-grid-item trial-grid-item--dish"
                        >
                          <div className="trial-grid-item__title">
                            {item.name}
                          </div>
                        </Link>
                      );
                    })}
                </div>
              ) : null
            ) : (
              <Link
                to={`/${this.props.data.route}/categories/next-category/dish`}
              >
                <img
                  className="trial-nav__logo"
                  src={RImages[`CategoryMenu-${this.props.data.id}`]}
                  alt="Menu categories"
                />
              </Link>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default TrialNextCategory;
