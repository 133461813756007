import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./styles/main.scss";

import RImages from "./assets/images/restaurants/**.jpg";
class TrialNPS extends Component {
    render() {
        return (
            <div className="trial-wrapper">
                <div className="trial">
                    <div className="container container--trial">
                        <div className="trial-nav">
                            <Link to={`/${this.props.data.route}`}>
                                <img
                                    className="trial-nav__back"
                                    src={require("./assets/icons/i-back.svg")}
                                    alt={this.props.data.name}
                                />
                            </Link>
                            <img
                                className="trial-nav__logo"
                                src={
                                    RImages[`logo-small-${this.props.data.id}`]
                                }
                                alt={this.props.data.name}
                            />
                        </div>
                        <div className="trial-title"></div>
                        <Link
                            to={`/${this.props.data.route}/upload`}
                            className="trial-nps__img"
                        >
                            <img
                                src={require("./assets/images/NPS.png")}
                                alt="NPS"
                            />
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default TrialNPS;
