import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './styles/main.scss';
import RImages from './assets/images/restaurants/**.jpg';

class TrialCategories extends Component {
  state = {
    data: []
  };

  componentDidMount() {
    axios
      .get(
        'https://mrtomato-qa.dev.trustsourcing.com/api/test/TEST_Denis_GetMenu?restId=450'
      )
      .then(response => this.setState({ data: response.data }));
  }

  render() {
    return (
      <div className="trial-wrapper">
        <div className="trial">
          <div className="container container--trial">
            <div className="trial-nav">
              <Link to={`/${this.props.data.route}`}>
                <img
                  className="trial-nav__back"
                  src={require('./assets/icons/i-back.svg')}
                  alt={this.props.data.name}
                />
              </Link>
              <img
                className="trial-nav__logo"
                src={RImages[`logo-small-${this.props.data.id}`]}
                alt={this.props.data.name}
              />
            </div>
            {this.props.data.id === 3 ? (
              this.state.data.length ? (
                <div className="trial-grid">
                  {this.state.data.map(category => {
                    const url = `https://mrtomato.live.trustsourcing.com/uploads/${
                      category.image
                    }`;

                    return (
                      <Link
                        to={`/${this.props.data.route}/categories/${
                          category.id
                        }`}
                        className="trial-grid-item"
                        style={
                          category.image && { backgroundImage: `url(${url})` }
                        }
                      >
                        <div className="trial-grid-item__title">
                          {category.name}
                        </div>
                      </Link>
                    );
                  })}
                </div>
              ) : null
            ) : (
              <Link to={`/${this.props.data.route}/categories/next-category`}>
                <img
                  className="trial-nav__logo"
                  src={RImages[`Menu-${this.props.data.id}`]}
                  alt="Menu categories"
                />
              </Link>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default TrialCategories;
