import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./styles/main.scss";

import RImages from "./assets/images/restaurants/**.jpg";

class TrialRequest extends Component {
    render() {
        return (
            <div className="trial-wrapper">
                <div className="trial">
                    <div className="container container--trial">
                        <div className="trial-nav">
                            <Link to={`/${this.props.data.route}`}>
                                <img
                                    className="trial-nav__back"
                                    src={require("./assets/icons/i-back.svg")}
                                    alt={this.props.data.name}
                                />
                            </Link>
                            <img
                                className="trial-nav__logo"
                                src={
                                    RImages[`logo-small-${this.props.data.id}`]
                                }
                                alt={this.props.data.name}
                            />
                        </div>
                        {this.props.location.state.target && (
                            <img
                                className="trial-request__img"
                                src={this.props.location.state.target}
                                alt="Success"
                            />
                        )}
                        <div className="trial-footer">
                            <p className="trial-footer__text">
                                Quality of service provides by
                            </p>
                            <img
                                src={require("./assets/icons/i-logo.svg")}
                                width="131"
                                alt="Mr.Tomato"
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TrialRequest;
